import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProductsToState, setLoader } from '../../redux';

import { usePostFile } from '../../hooks/usePostFile';
import { usePostImage } from '../../hooks/usePostImage';
import { useFormatFilename } from '../../hooks/useFormatFilename';

import SearchBar from '../../components/SearchBar';
import AlertMessage from '../../components/AlertMessage';

import "./style.css";

import trash from './../../assets/svg/svg_trash_red.svg';

const init_form = {
    head: ['References', '3D-plan'],
    references: Array.from({ length: 4 }, () => ({ References: '', '3D-plan': '' }))
};

function CreateReferences() {
    const dispatch = useDispatch();
    const postFile = usePostFile();
    const postImage = usePostImage();
    const formatFilename = useFormatFilename();

    const token = useSelector(state => state.user.token);
    const products_db = useSelector(state => state.data.products);

    const [search, setSearch] = useState({ id: '', value: '' });
    const [formData, setFormData] = useState(JSON.parse(JSON.stringify(init_form)));
    const [alertMsg, setAlert] = useState([]);

    const handleHead = (e, idx) => {
        const value = e.target.value || '';
        if (value === 'References' || value === '3D-plan' || value === '') return;

        const oldHead = formData.head[idx];
        let new_form = { ...formData };
        new_form.head[idx] = value;

        // Synchroniser les clés des objets dans references
        new_form.references = new_form.references.map(ref => {
            const updatedRef = { ...ref };

            if (oldHead in updatedRef) {
                updatedRef[value] = updatedRef[oldHead];
                delete updatedRef[oldHead];
            }
            else updatedRef[value] = '';
            return updatedRef;
        });

        setFormData(new_form);
    };

    const handleValue = (e, id, field) => {
        let new_form = { ...formData };
        new_form.references[id][field] = e.target.value;

        setFormData(new_form);
    };

    const handleSchema = (e, id, field) => {
        const file = e.target.files[0];
        let new_form = { ...formData };

        new_form.references[id][field] = file;
        setFormData(new_form);
    };

    const delSchema = (idx, head) => {
        let new_form = { ...formData };
        new_form.references[idx][head] = '';
        setFormData(new_form);
    };

    const addRow = () => {
        let new_form = { ...formData };
        let new_row = {};

        formData.head.forEach(head => new_row[head] = '');
        new_form.references.push(new_row);
        setFormData(new_form);
    };

    const delRow = (idx) => {
        const newReferences = formData.references.filter((_, i) => i !== idx);
        setFormData({ ...formData, references: newReferences });
    };

    const addColumn = () => {
        let new_form = {
            head: [...formData.head, `Column-${formData.head.length - 1}`],
            references: formData.references.map(ref => ({ ...ref, [`Column ${formData.head.length - 1}`]: '' }))
        };

        setFormData(new_form);
    };

    const delColumn = (head) => {
        const new_form = {
            head: formData.head.filter(e => e !== head),
            references: formData.references.map(ref => {
                const { [head]: _, ...rest } = ref;
                return rest;
            }),
        };

        setFormData(new_form);
    };

    const downRef = (idx) => {
        if (idx === 0) return;

        const newReferences = [...formData.references];
        // Échanger les éléments
        [newReferences[idx], newReferences[idx - 1]] = [newReferences[idx - 1], newReferences[idx]];

        setFormData({ ...formData, references: newReferences });
    };

    const upRef = (idx) => {
        if (idx === formData.references.length - 1) return;

        const newReferences = [...formData.references];
        // Échanger les éléments
        [newReferences[idx], newReferences[idx + 1]] = [newReferences[idx + 1], newReferences[idx]];

        setFormData({ ...formData, references: newReferences });
    };

    const setColumnToLeft = (idx) => {
        if (idx <= 2) return

        const new_head = [...formData.head];
        [new_head[idx], new_head[idx - 1]] = [new_head[idx - 1], new_head[idx]];
        setFormData({ ...formData, head: new_head });
    }

    const setColumnToRight = (idx) => {
        if (idx === formData.head.length - 1) return

        const new_head = [...formData.head];
        [new_head[idx], new_head[idx + 1]] = [new_head[idx + 1], new_head[idx]];
        setFormData({ ...formData, head: new_head });
    }

    const isValidFileExtension = (file) => {
        const validExtensions = ['.pdf', '.step', '.dwg', '.dxf', '.igs'];
        const extension = validExtensions.find(ext => file.name.toLowerCase().endsWith(ext));
        return !!extension;
    };

    const isValidImgExtension = (file) => {
        const validExtensions = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];
        const response = validExtensions.includes(file.type);
        return response;
    };


    const validation = async () => {
        if (search.id === '') {
            setAlert([...alertMsg, {
                title: 'Produit non renseigné',
                message: `Le produit au quel vous souhaitez ajouter des références n'a pas été selectionné`
            }]);
            return
        }
        dispatch(setLoader(1));

        const response = await Promise.all(formData.references.map(ref => postReference(ref)));

        let references = [];
        let referencesId = [];

        for (let i = 0; i < response.length; i++) {
            if (typeof response[i] !== 'boolean') referencesId.push(response[i]);
            else references.push(formData.references[i]);
        }

        const req = await fetch(`https://api.pro-vide.eu/admin/product/${search.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ references: { head: formData.head, data: referencesId } })
        });
        const res = await req.json();

        if (req.status !== 200 || !res) {
            setAlert([...alertMsg, {
                title: 'Grosse Perte De Données',
                message: `Un probleme est survenu lors de l'ajout du tableau de références au produit. 
                Contactez le développeur du site et donnez lui ces données : ${referencesId.map(id => `'${id}' `)}`
            }]);
            dispatch(setLoader(-1));
            return
        }
        dispatch(setLoader(-1));

        const product = products_db.find(prod => prod._id === search.id);
        const product_updated = JSON.parse(JSON.stringify(product));

        product_updated.references.head = formData.head;
        product_updated.references.data = referencesId;

        const new_products_db = products_db.filter(prod => prod._id !== search.id);

        dispatch(addProductsToState([...new_products_db, product_updated]));

        setSearch({ id: '', value: '' });
        setFormData({ head: formData.head, references: JSON.parse(JSON.stringify(init_form.references)) });
        setAlert([...alertMsg, {
            title: 'Référence(s) ajouté(s)',
            message: `Le tableau de références de '${search.value}' à bien été créé`
        }]);
    };

    const postReference = async (reference) => {
        if (reference.References === '' || reference.References === ' ') return true

        let new_reference = {};

        formData.head.map(head => new_reference[head] = reference[head]);

        if (reference['3D-plan'] !== '') {
            const form = new FormData();
            let file_id = '';

            if (isValidImgExtension(reference['3D-plan'])) {
                form.append('image', reference['3D-plan']);
                file_id = await postImage(setAlert, form);
            }
            else if (isValidFileExtension(reference['3D-plan'])) {
                form.append('file', reference['3D-plan']);
                file_id = await postFile(setAlert, form);
            }
            new_reference['3D-plan'] = file_id;
            if (!file_id || file_id === '') return false
        }

        const req = await fetch(`https://api.pro-vide.eu/admin/reference/${search.id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(new_reference)
        });
        const res = await req.json();

        // Erreurs classiques
        if ([404, 401, 400].includes(req.status)) {
            if (reference['3D-plan'] !== '') setAlert([...alertMsg, {
                title: 'Erreur Perte De Données',
                message: `Un probleme est survenu lors de la création de la référence. Le 3D-plan a tout de même été téléchargé. 
                Prévenez le développeur du site des que possible avec cette _id: '${new_reference['3D-plan']}'`
            }]);
            setAlert([...alertMsg, {
                title: 'Une erreur c\'est produite',
                message: res.message
            }]);
            return false
        }
        // Erreur serveur
        if (req.status !== 201 || !res) {
            if (reference['3D-plan'] !== '') setAlert([...alertMsg, {
                title: 'Erreur Perte De Données',
                message: `Un probleme est survenu lors de la création de la référence. Le 3D-plan a tout de même été téléchargé. 
                Prévenez le développeur du site des que possible avec cette _id: '${new_reference['3D-plan']}'`
            }]);
            setAlert([...alertMsg, {
                title: 'Serveur indisponible',
                message: res.message
            }]);
            return false
        }
        return res._id
    };


    return (
        <div className='modal modal_references modal_create_references'>
            <h1>Ajout de références</h1>

            <SearchBar search={search} setSearch={setSearch} setFormData={setFormData} type='create' />

            <div className="table_ref">
                <ul>
                    <li className='th_order'><p className='input_table'>Ordre</p></li>

                    {formData.head.map((head, idx) => (
                        <li key={idx}>
                            {head === 'References' || head === '3D-plan' ? (
                                <input
                                    readOnly
                                    type="text"
                                    value={head}
                                />
                            ) : (
                                <input
                                    type="text"
                                    value={head}
                                    placeholder='En-tête'
                                    onChange={(e) => handleHead(e, idx)}
                                />
                            )}

                            {head !== 'References' && head !== '3D-plan' &&
                                <button
                                    type='button'
                                    className='btn_del_row'
                                    onClick={() => delColumn(head)}
                                ><img src={trash} alt="X" /></button>
                            }
                        </li>
                    ))}

                    <li className='li_add_column'>
                        <button
                            type='button'
                            className='btn_add_column'
                            onClick={() => addColumn()}
                        ><i className="fas fa-chevron-right"></i></button>
                    </li>
                </ul>

                {formData.references.map((ref, idx) => (
                    <ul key={idx}>
                        <li className='th_order'>
                            <button>
                                <i onClick={() => downRef(idx)} className="fas fa-chevron-up"></i>
                                <i onClick={() => upRef(idx)} className="fas fa-chevron-down"></i>
                            </button>
                        </li>

                        {formData.head.map((head, id) => (
                            <li key={id}>
                                {head !== '3D-plan' &&
                                    <input
                                        type="text"
                                        value={ref[head] ?? ''}
                                        placeholder={`"${head}"`}
                                        onChange={(e) => handleValue(e, idx, head)}
                                    />
                                }

                                {head === '3D-plan' &&
                                    <div className="input_schema">
                                        <input type="file" onChange={(e) => handleSchema(e, idx, head)} />

                                        {ref[head].name && <label>{formatFilename(ref[head].name)}</label>}
                                        {!ref[head] && <label>+ Ajouter un schéma</label>}

                                        {ref[head] &&
                                            <button
                                                type='button'
                                                className='btn_del_row'
                                                onClick={() => delSchema(idx, head)}
                                            ><img src={trash} alt="X" /></button>
                                        }
                                    </div>
                                }

                                {head === 'References' && (
                                    <button
                                        type='button'
                                        className='btn_del_row'
                                        onClick={() => delRow(idx)}
                                    ><img src={trash} alt="X" /></button>
                                )}
                            </li>
                        ))}
                        <li className='li_add_column'>
                            <i type='button' className='btn_add_column'></i>
                        </li>
                    </ul>
                ))}

                <div className="panel_references">
                    <button
                        type='button'
                        className='btn_add_row'
                        onClick={() => addRow()}
                    >Ajouter une nouvelle ligne</button>

                    <ul className='border_right'>
                        {formData.head.map((e, idx) => idx > 1 &&
                            <li key={idx}>
                                <i
                                    className='fas fa-chevron-left'
                                    onClick={() => setColumnToLeft(idx)}
                                ></i>

                                <p>{e}</p>

                                <i
                                    className='fas fa-chevron-right'
                                    onClick={() => setColumnToRight(idx)}
                                ></i>
                            </li>
                        )}

                        <i type='button' className='btn_add_column'></i>
                    </ul>
                </div>
            </div>

            <button onClick={() => validation()} className='btn_validation'>Créer les références</button>

            {alertMsg.length !== 0 && <AlertMessage alertMsg={alertMsg} setAlert={setAlert} />}
        </div>
    );
}

export default CreateReferences;
