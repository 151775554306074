import { setLang, setModal, setPage, setPageIsEmpty } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useGoPage } from "../../hooks/useGoPage";

import "./style.css"

function ConfirmStop() {
    const goPage = useGoPage();
    const dispatch = useDispatch();
    const process = useSelector(state => state.process);

    const confirm = () => {
        dispatch(setLang('fr'));
        
        if (process.w_modal !== '') {
            dispatch(setPageIsEmpty(true));
            dispatch(setModal(process.w_modal));
            return
        }
        if (process.w_page !== '') {
            goPage(process.w_page);
            
            dispatch(setPageIsEmpty(true));
            dispatch(setModal('list'));
            dispatch(setPage(''));
        }
    }

    const cancel = () => {
        if (process.w_modal !== '') {
            dispatch(setModal(null));
            return
        }
        if (process.w_page !== '') {
            dispatch(setPage(''));
        }
    }

    return (
        <div className="mdl_msg_of_verif">
            <div className="content">
                <span>Attention</span>
                <p>Vous vous apprétez à annuler la saisie des données <br /><br />Êtes vous sûre ?</p>

                <div className="panel">
                    <button onClick={() => cancel()} className='red'>non</button>
                    <button onClick={() => confirm()}>oui</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmStop;