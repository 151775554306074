import { useDispatch, useSelector } from "react-redux";
import { setPageIsEmpty } from "../redux";

export const usePageIsEmpty = () => {
    const dispatch = useDispatch();
    const process = useSelector(state => state.process);

    const pageIsEmpty = (form) => {
        const textFields = ['name_fr', 'name_en', 'name_sp', 'name_de', 'text_fr', 'text_en', 'text_sp', 'text_de'];
        const hasTextContent = textFields.some(field => form[field] !== '');

        if (process.pageIsEmpty) {
            if (hasTextContent || (form.image && form.image.value !== '') || (form.images && form.images.some(img => img.value !== ''))) {
                dispatch(setPageIsEmpty(false));
            }
        } 
        else {
            if (!hasTextContent && ((form.image && form.image.value === '') || (form.images && !form.images.some(img => img.value !== '')))) {
                dispatch(setPageIsEmpty(true));
            }
        }
    };

    return pageIsEmpty;
};