import ReactPlayer from "react-player";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useFetchFile } from "../../hooks/useFetchFile";
import { useFetchImage } from "../../hooks/useFetchImage";
import { useFormatText } from "../../hooks/useFormatText";
import { useFormatFilename } from '../../hooks/useFormatFilename';

import PanelVerif from './../../components/PanelVerif';
import Slider from "../../components/Slider";

import "./style.css"

const langs = ['fr', 'en', 'sp', 'de'];

function VerifServices() {
    const fetchFile = useFetchFile();
    const fetchImage = useFetchImage();
    const formatText = useFormatText();
    const formatFilename = useFormatFilename();

    const lang = useSelector(state => state.user.lang);
    const token = useSelector(state => state.user.token);
    const services_db = useSelector(state => state.data.services);

    const [services_list, setServicesList] = useState([]);
    const [alertMsg, setAlert] = useState(null);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        if (!services_db || services_list.length !== 0) return

        main();

        async function main() {
            let new_servicesList = services_db.filter(serv => serv.online === false);
            // Créez une copie des objets pour éviter de modifier les objets d'origine
            new_servicesList = new_servicesList.map(service => ({ ...service }));

            for (let i = 0; i < new_servicesList.length; i++) {
                const service = new_servicesList[i];
                let images = [];

                if (!service.images || service.images.length === 0) continue

                for (let j = 0; j < service.images.length; j++) {
                    const image = service.images[j];

                    const img_service = await fetchImage(image);
                    images.push(img_service);

                }
                new_servicesList[i].images = images;

                let files = {
                    fr: [],
                    en: [],
                    sp: [],
                    de: []
                };

                for (let lg of langs) {
                    let file_lang = [];

                    for (let j = 0; j < service.files[lg].length; j++) {
                        const file = service.files[lg][j];

                        const file_service = await fetchFile(file);
                        file_lang.push(file_service.file);
                    }
                    files[lg] = file_lang;
                }
                new_servicesList[i].files = files;
            }

            setServicesList(new_servicesList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [services_db])

    const validation = async () => {
        const req = await fetch(`https://api.pro-vide.eu/admin/service/${services_list[counter]._id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ online: true })
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 401];

        // Erreurs classiques
        if (code.includes(req.status)) setAlert({
            title: 'Une erreur c\'est produite lors de l\'envoi de votre image',
            message: res.message
        });

        // Erreur serveur
        if (req.status === 500 || !res) setAlert({
            title: 'Erreur logique, contactez le développeur',
            message: res.message
        });

        if (req.status !== 200 || !res) return

        if (counter !== 0 && counter === services_list.length - 1) {
            setCounter(counter - 1);
        }

        const new_list = services_list.filter(serv => serv._id !== services_list[counter]._id);
        setServicesList(new_list);

        setAlert({
            title: 'Validé',
            message: 'Le service à bien été mis en ligne'
        });
    }

    return (
        <div className='modal modal_verif modal_verif_services'>
            <h1>Validation des services</h1>

            <PanelVerif counter={counter} setCounter={setCounter} list={services_list} validation={validation} />

            {services_list.length !== 0 &&
                <section className="sect_verif sect_verif_services">
                    {services_list[counter].video && services_list[counter].video !== '' &&
                        <div className="player">
                            <ReactPlayer width='760px' height='360px' url={services_list[counter].video} />
                        </div>
                    }

                    <button className='onglet'>
                        <div className='preview'>
                            <img src={services_list[counter].images[0] && services_list[counter].images[0].image} alt="error_image" />
                        </div>

                        <span>{services_list[counter][`name_${lang}`]}</span>
                    </button>

                    <div className="descrip_and_slider">
                        <div className="descrip">
                            <h2>{services_list[counter][`name_${lang}`]}</h2>
                            <p>{formatText(services_list[counter][`text_${lang}`])}</p>
                            <button className='btn_descr'>Faire une demande</button>
                        </div>

                        <div className="slider_and_flyers">
                            <Slider
                                data={services_list[counter]}
                                className='services'
                                btn_type='request'
                                img_type='multi'
                            />

                            <div className="flyers">
                                <h4>À télécharger :</h4>

                                {services_list[counter].files[lang].length !== 0 &&
                                    <ul>
                                        {services_list[counter].files[lang].map((file, idx) =>
                                            <li key={idx}>
                                                <button>
                                                    {formatFilename(file)}
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            }

            {alertMsg &&
                <div className="mdl_msg_of_verif">
                    <div className="content">
                        <span>{alertMsg.title}</span>
                        <p>{alertMsg.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default VerifServices;