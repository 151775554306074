import { useSelector } from "react-redux";

import "./style.css"

function NameAndText({ formData, setFormData, placeholder }) {
    const lang = useSelector(state => state.user.lang);

    const setValue = (e, field) => {
        const value = e.target.value;

        setFormData({
            ...formData,
            [field]: value
        });
    }

    return (
        <div className="name_and_text">
            <div className="input_text">
                <label>Nom {placeholder} :</label>

                <input
                    type="text"
                    placeholder={'Nom ' + placeholder}

                    value={formData[`name_${lang}`]}
                    onChange={(e) => setValue(e, `name_${lang}`)}
                />
            </div>

            <div className="textarea">
                <label>Description {placeholder} :</label>

                <textarea
                    placeholder={'Description ' + placeholder}

                    value={formData[`text_${lang}`]}
                    onChange={(e) => setValue(e, `text_${lang}`)}
                ></textarea>
            </div>
        </div>
    )
}

export default NameAndText;