import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../redux";

import { useFetchFile } from "../../hooks/useFetchFile";
import { useFetchImage } from "../../hooks/useFetchImage";

import "./style.css"

import trash from './../../assets/svg/svg_trash_red.svg';

const langs = ['fr', 'en', 'sp', 'de'];

function ProductSelector({ formData, setFormData, setLastData, setToDelete }) {
    const dispatch = useDispatch();

    const fetchFile = useFetchFile();
    const fetchImage = useFetchImage();

    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [path, setPath] = useState(null);

    const token = useSelector(state => state.user.token);
    const products_db = useSelector(state => state.data.products);
    const categories_db = useSelector(state => state.data.categories);

    useEffect(() => {
        if (!categories_db || !products_db) return

        const new_categories = categories_db.filter(cat => cat.type === 'prod' && (path ? cat.parentId === path : !cat.parentId));
        setCategories(new_categories);

        const new_products = products_db.filter(prod => prod.parentId === path);
        setProducts(new_products);

    }, [categories_db, products_db, path])

    const previous = () => {
        const previous_category = categories_db.find(cat => cat._id === path && cat.type === 'prod');
        setPath(previous_category.parentId || null);
    }

    const goPath = (new_path) => {
        const categories_children = categories_db.filter(cat => cat.parentId === new_path._id && cat.type === 'prod');
        if (categories_children.length !== 0) setPath(new_path._id);

        const products_children = products_db.filter(prod => prod.parentId === new_path._id);
        if (products_children.length !== 0) setPath(new_path._id);
    }

    const selectProduct = async (product) => {
        if (!formData) {
            setToDelete(product);
            return
        }

        dispatch(setLoader(1));

        const req = await fetch(`https://api.pro-vide.eu/admin/product/${product._id}`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` }
        })
        const res = await req.json();

        if (req.status !== 200 || !res) {
            alert('Impossible de récupérer les données du produit');
            dispatch(setLoader(-1));
            return
        }

        let images = Array(9).fill({
            value: '',
            prev: null
        });

        let files = {
            fr: [],
            en: [],
            sp: [],
            de: []
        }

        // Recup des images
        if (res.images.length !== 0) {
            for (let i = 0; i < res.images.length; i++) {
                const image_product = await fetchImage(res.images[i]);

                if (image_product) images[i] = {
                    value: '',
                    _id: image_product._id,
                    prev: image_product.image
                }
            }
        }

        // Recup des fichiers
        for (let lang of langs) {
            if (res.files[lang].length === 0) continue

            for (let file_id of res.files[lang]) {
                const file = await fetchFile(file_id);

                if (!file) continue

                files[lang].push({
                    _id: file_id,
                    name: file.file
                })
            }
        }

        const new_form = {
            _id: product._id,

            name_fr: res.name_fr || '',
            name_en: res.name_en || '',
            name_sp: res.name_sp || '',
            name_de: res.name_de || '',

            text_fr: res.text_fr || '',
            text_en: res.text_en || '',
            text_sp: res.text_sp || '',
            text_de: res.text_de || '',

            benefits_fr: res.benefits_fr || Array(5).fill(''),
            benefits_en: res.benefits_en || Array(5).fill(''),
            benefits_sp: res.benefits_sp || Array(5).fill(''),
            benefits_de: res.benefits_de || Array(5).fill(''),

            features_fr: res.features_fr || Array(5).fill(''),
            features_en: res.features_en || Array(5).fill(''),
            features_sp: res.features_sp || Array(5).fill(''),
            features_de: res.features_de || Array(5).fill(''),

            ref_descrip_fr: res.ref_descrip_fr || '',
            ref_descrip_en: res.ref_descrip_en || '',
            ref_descrip_sp: res.ref_descrip_sp || '',
            ref_descrip_de: res.ref_descrip_de || '',

            associated: res.associated || [],
            parentId: res.parentId || '',
            images: images,
            files: files
        }

        dispatch(setLoader(-1));

        setFormData(new_form);
        setLastData(new_form);
    }

    return (
        <div className='product_selector'>
            {(categories.length !== 0 || products.length !== 0) &&
                <ul className='list'>
                    {path &&
                        <li className='li_blue'>
                            <button
                                type='button'
                                className='path'
                                onClick={() => previous()}
                            >
                                Revenir en arrière
                            </button>
                        </li>
                    }

                    {categories.length !== 0 && categories.map((category, idx) =>
                        <li key={idx} className={path ? 'li_yellow' : 'li_blue'}>
                            <button
                                type='button'
                                className='path'
                                onClick={() => goPath(category)}
                            >{category.name_fr}</button>
                        </li>
                    )}

                    {products.length !== 0 && products.map((product, idx) =>
                        <li key={idx} className={path ? 'li_red' : 'li_blue'}>
                            <button
                                type='button'
                                className='path'
                                onClick={() => goPath(product)}
                            >{product.name_fr}</button>

                            {formData &&
                                <button
                                    type='button'
                                    className='check_box'
                                    onClick={() => selectProduct(product)}
                                >
                                    {formData.parentId === product._id && <i>✔</i>}
                                </button>
                            }

                            {!formData &&
                                <button
                                    type='button'
                                    className='btn_delete'
                                    onClick={() => selectProduct(product)}
                                >
                                    <img src={trash} alt="X" />
                                </button>
                            }
                        </li>
                    )}
                </ul>
            }

            {categories.length === 0 && products.length === 0 &&
                <ul className='void_list'>
                    {path &&
                        <li className='li_blue'>
                            <button
                                type='button'
                                className='path'
                                onClick={() => setPath(null)}
                            >
                                Revenir en arrière
                            </button>
                        </li>
                    }
                    <li className='no_value'>Aucune données</li>
                </ul>
            }
        </div>
    )
}

export default ProductSelector;