import "./style.css"

function InputList({ placeholder, formData, setFormData, idx, field }) {
    const updtValue = (e) => {
        let newList = formData[field];
        newList[idx] = e.target.value;

        setFormData({
            ...formData,
            [field]: newList
        })
    }

    const changeUp = () => {
        if (idx === 0) return

        const newList = [...formData[field]];
        [newList[idx], newList[idx - 1]] = [newList[idx - 1], newList[idx]];

        setFormData({
            ...formData,
            [field]: newList
        });
    }

    const changeDown = () => {
        if (idx === formData[field].length - 1) return

        const newList = [...formData[field]];
        [newList[idx], newList[idx + 1]] = [newList[idx + 1], newList[idx]];

        setFormData({
            ...formData,
            [field]: newList
        });
    }

    return (
        <li className="input_list">
            <input
                type="text"
                placeholder={placeholder}

                value={formData[field][idx]}
                onChange={e => updtValue(e)}
            />

            <div className="panel_liste">
                <button
                    type="button"
                    className='btn_change_pos'
                    onClick={() => changeUp()}
                ><i className='fas fa-chevron-up'></i></button>
                <button
                    type="button"
                    className='btn_change_pos'
                    onClick={() => changeDown()}
                ><i className='fas fa-chevron-down'></i></button>
            </div>
        </li>
    )
}

export default InputList;