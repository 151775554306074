import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../redux";

export const useGetAllData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const token = useSelector(state => state.user.token);

    const getAllData = async () => {
        dispatch(setLoader(1));

        const req = await fetch(`https://api.pro-vide.eu/admin/all_data`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const res = await req.json();

        dispatch(setLoader(-1));

        const categories = res.categories.sort((a, b) => a.order - b.order);
        const products = res.products.sort((a, b) => a.order - b.order);
        const services = res.services.sort((a, b) => a.order - b.order);
        const achievments = res.achievments.sort((a, b) => a.order - b.order);

        if (req.status === 200 && res) return { categories, products, services, achievments }

        window.scrollTo(0, 0);
        navigate(req.status === 401 ? '/login' : '/error');
        return { categories: [], products: [], services: [], achievments: [] }
    };
    return getAllData;
};
