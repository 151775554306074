import { useSelector } from "react-redux";

import ModalSelector from "../../selectors/Modal";

import ModalProducts from "../../modal/ModalProducts";
import CreateProducts from "../../modalCreate/CreateProducts";
import UpdateProducts from "../../modalUpdate/UpdateProducts";
import DeleteProducts from "../../modalDelete/DeleteProducts";
import VerifProducts from "../../modalVerif/VerifProducts";

import "./style.css"

function Products() {
    const process = useSelector(state => state.process);

    return (
        <div className='page page_products'>
            <ModalSelector page='produit' un='un' />

            {process.modal === 'list' && <ModalProducts />}
            {process.modal === 'create' && <CreateProducts />}
            {process.modal === 'update' && <UpdateProducts />}
            {process.modal === 'delete' && <DeleteProducts />}
            {process.modal === 'verif' && <VerifProducts />}
        </div>
    )
}

export default Products;