import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../redux";

export const useDeleteImage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const token = useSelector(state => state.user.token);

    const deleteImage = async (_id, setAlert) => {
        dispatch(setLoader(1));

        const req = await fetch(`https://api.pro-vide.eu/admin/del-img/${_id}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const res = await req.json();

        dispatch(setLoader(-1));

        if (req.status === 401) navigate('/login');
        if ([404].includes(req.status)) {
            setAlert(prev => [...prev, {
                title: 'Image introuvable',
                message: res.message
            }]);
            return false
        }
        if (req.status === 400 || !res) {
            setAlert(prev => [...prev, {
                title: 'Erreur logique, contactez le développeur',
                message: res.message
            }]);
            return false
        }
        return res
    };
    return deleteImage;
};
