import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./style.css"

function SelecteAssociated({ formData, setFormData }) {
    const [parent, setParent] = useState('');
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);

    const products_db = useSelector(state => state.data.products);
    const categories_db = useSelector(state => state.data.categories);

    
    // Mise à jour affichage des éléments
    useEffect(() => {
        if (!formData || !categories_db) return

        const categories_list = categories_db.filter(cat => !cat.parentId && cat.type === 'prod');
        setCategories(categories_list);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Mise à jour affichage des éléments
    useEffect(() => {
        if ((!parent && parent !== '') || !categories_db) return

        if (parent === '') {
            const categories_list = categories_db.filter(cat => !cat.parentId && cat.type === 'prod');
            setCategories(categories_list);
            setProducts([]);
            return
        }

        const categories_list = categories_db.filter(cat => cat.parentId === parent);
        setCategories(categories_list);

        if (!categories_list || categories_list.length === 0) {
            const products_list = products_db.filter(prod => prod.parentId === parent);
            setProducts(products_list);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parent, categories_db, products_db]);


    const previous = () => {
        const cat_parent = categories_db.find(cat => cat._id === parent);
        setParent(cat_parent && cat_parent.parentId ? cat_parent.parentId : '');
        setProducts([]);
    }

    const chooseParent = (_id, type) => {
        if (type === 'prod') {
            const check = formData.associated.find(prod => prod === _id);
            let associated = [ ...formData.associated ];

            if (check) associated = associated.filter(prod => prod !== _id);
            else associated = [ ...associated, _id ];

            setFormData({
                ...formData,
                associated: associated
            })
        }
    }


    return (
        <section className='sect_associated'>
            <h2>Catégories & Produits associés :</h2>

            <ul className="select_list">
                {parent !== '' &&
                    <li className='first'>
                        <button type='button' onClick={() => previous()} >Revenir en arrière</button>
                    </li>
                }

                {categories && categories.length !== 0 && categories.map((cat, idx) =>
                    <li
                        key={idx}
                        className={parent === '' ? 'first' : ''}
                    >
                        <button type='button'>
                            <p onClick={() => setParent(cat._id)}>{cat.name_fr}</p>
                            
                            {/* <i
                                className='check'
                                onClick={() => chooseParent(cat._id, 'cat')}
                            >{formData.associated_cat.includes(cat._id) ? '✓' : ''}</i> */}
                        </button>
                    </li>
                )}

                {products && products.length !== 0 && products.map((prod, idx) =>
                    <li key={idx} className='product'>
                        <button
                            type='button'
                            className={prod._id === formData.parentId ? 'actif' : ''}
                        >
                            <p>{prod.name_fr}</p>
                            <i
                                className='check'
                                onClick={() => chooseParent(prod._id, 'prod')}
                            >{formData.associated.includes(prod._id) ? '✓' : ''}</i>
                        </button>
                    </li>
                )}

                {categories.length === 0 && products.length === 0 &&
                    <li>
                        <p className='no_value'>Aucune données</p>
                    </li>
                }
            </ul>
        </section>
    )
}

export default SelecteAssociated;