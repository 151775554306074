import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addCategoriesToState } from "../../redux";

import { useSetOrder } from "../../hooks/useSetOrder";
import { useFetchImage } from '../../hooks/useFetchImage';

import AlertMessage from '../../components/AlertMessage';

import "./style.css"

import icon_return from '../../assets/img/icon_return.png'

function ModalCategories() {
    const dispatch = useDispatch();
    const setOrder = useSetOrder();
    const fetchImage = useFetchImage();

    const [data, setData] = useState([]);
    const [path, setPath] = useState(null);
    const [type, setType] = useState('prod');
    const [alertMsg, setAlert] = useState([]);

    const categories_db = useSelector(state => state.data.categories);

    useEffect(() => {
        if (!categories_db || !type) return

        const categories = categories_db.filter(cat => cat.type === type && (path ? cat.parentId === path : !cat.parentId));

        const fetchImages = async (categories) => {
            let new_list = [];

            for (let category of categories) {
                let new_data = { ...category }

                if (category.image) {
                    const img_category = await fetchImage(category.image);
                    new_data.image = img_category;
                }
                new_list.push(new_data);
            }
            setData(new_list);
        }
        fetchImages(categories);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories_db, path, type])

    const changeType = (new_type) => {
        setType(new_type);
        setPath(null);
    }

    const previous = () => {
        const parent = categories_db.find(cat => cat._id === path);
        setPath(parent.parentId);
    }

    const goTo = (_id) => {
        const childrens = categories_db.filter(cat => cat.type === type && cat.parentId === _id);
        if (childrens.length !== 0) setPath(_id);
    }

    const changePosPrev = async (category) => {
        if (category.order === 0) return;

        const prev_element = data.find(cat => cat.order === category.order - 1);

        if (!prev_element) {
            setAlert([...alertMsg, {
                title: `Erreur`,
                message: 'Contactez le développeur'
            }]);
            return;
        }

        const response = await setOrder('category', category._id, prev_element._id, setAlert);
        if (response !== true) return;

        const updatedCategories = categories_db.map(cat => {
            if (cat._id === category._id) {
                return { ...cat, order: cat.order - 1 };
            }
            else if (cat._id === prev_element._id) {
                return { ...cat, order: cat.order + 1 };
            }
            return cat;
        });
        dispatch(addCategoriesToState(updatedCategories.sort((a, b) => a.order - b.order)));

        const updatedData = data.map(cat => {
            if (cat._id === category._id) {
                return { ...cat, order: cat.order - 1 };
            }
            else if (cat._id === prev_element._id) {
                return { ...cat, order: cat.order + 1 };
            }
            return cat;
        });
        setData(updatedData.sort((a, b) => a.order - b.order));
    };

    const changePosNext = async (category) => {
        if (category.order === data.length - 1) return;

        const next_element = data.find(cat => cat.order === category.order + 1);

        if (!next_element) {
            setAlert([...alertMsg, {
                title: `Erreur`,
                message: 'Contactez le développeur'
            }]);
            return;
        }

        const response = await setOrder('category', category._id, next_element._id, setAlert);
        if (response !== true) return;

        const updatedCategories = categories_db.map(cat => {
            if (cat._id === category._id) {
                return { ...cat, order: cat.order + 1 };
            }
            else if (cat._id === next_element._id) {
                return { ...cat, order: cat.order - 1 };
            }
            return cat;
        });
        dispatch(addCategoriesToState(updatedCategories.sort((a, b) => a.order - b.order)));

        const updatedData = data.map(cat => {
            if (cat._id === category._id) {
                return { ...cat, order: cat.order + 1 };
            }
            else if (cat._id === next_element._id) {
                return { ...cat, order: cat.order - 1 };
            }
            return cat;
        });
        setData(updatedData.sort((a, b) => a.order - b.order));
    };


    return (
        <div className='modal modal_show modal_categories'>
            <button
                type='button'
                onClick={() => changeType('prod')}
                className={`select_type_prod ${type === 'prod' ? 'actif' : ''}`}
            >Produit</button>

            <button
                type='button'
                onClick={() => changeType('serv')}
                className={`select_type_serv ${type === 'serv' ? 'actif' : ''}`}
            >Service</button>

            <h1>Mes catégories</h1>

            {data.length !== 0 &&
                <ul className="categories_list_order">
                    {path &&
                        <li className="onglet_return_category">
                            <button
                                className='onglet_categories'
                                onClick={() => previous()}
                            >
                                <div className='preview'>
                                    <img
                                        src={icon_return}
                                        alt='Illustration'
                                    />
                                </div>

                                <span>Retour en arrière</span>
                            </button>
                        </li>
                    }

                    {data.map((cat, idx) =>
                        <li className="ctn_onglet_category" key={idx}>
                            <button
                                className="btn_prev"
                                onClick={() => changePosPrev(cat)}
                            >
                                <i className="fas fa-chevron-left"></i>
                            </button>

                            <button
                                className='onglet_categories'
                                onClick={() => goTo(cat._id)}
                            >
                                <div className='preview'>
                                    <img
                                        src={cat && cat.image && cat.image.image}
                                        alt='Illustration'
                                    />
                                </div>

                                <span>{cat.name_fr}</span>
                            </button>

                            <button
                                className="btn_next"
                                onClick={() => changePosNext(cat)}
                            >
                                <i className="fas fa-chevron-right"></i>
                            </button>
                        </li>
                    )}
                </ul>
            }

            {alertMsg.length !== 0 && <AlertMessage alertMsg={alertMsg} setAlert={setAlert} />}
        </div>
    )
}

export default ModalCategories;