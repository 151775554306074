import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../redux";

import { useFetchImage } from "../../hooks/useFetchImage";

import "./style.css"

import trash from './../../assets/svg/svg_trash_red.svg';

function AchievmentSelector({ formData, setFormData, setLastData, setToDelete }) {
    const dispatch = useDispatch();
    const fetchImage = useFetchImage();

    const [categories, setCategories] = useState([]);
    const [achievments, setAchievments] = useState([]);
    const [path, setPath] = useState(null);

    const token = useSelector(state => state.user.token);
    const achievments_db = useSelector(state => state.data.achievments);
    const categories_db = useSelector(state => state.data.categories);

    useEffect(() => {
        if (!categories_db || !achievments_db) return

        if (!path) {
            const new_categories = categories_db.filter(cat => !cat.parentId);
            setCategories(new_categories);
            setAchievments([]);
            return
        }

        const new_achievments = achievments_db.filter(achiev => achiev.parentId === path);
        setAchievments(new_achievments);
        setCategories([]);

    }, [categories_db, achievments_db, path])

    const goPath = (new_path) => {
        const achievments_children = achievments_db.filter(achiev => achiev.parentId === new_path._id);
        if (achievments_children.length !== 0) setPath(new_path._id);
    }

    const selectAchievment = async (achievment) => {
        if (!formData) {
            setToDelete(achievment);
            return
        }

        dispatch(setLoader(1));

        const req = await fetch(`https://api.pro-vide.eu/admin/achievement/${achievment._id}`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` }
        })
        const res = await req.json();

        if (req.status !== 200 || !res) {
            alert('Impossible de récupérer les données du réalisation');
            dispatch(setLoader(-1));
            return
        }

        let images = Array(9).fill({
            value: '',
            prev: null
        });

        // Recup des images
        if (res.images.length !== 0) {
            for (let i = 0; i < res.images.length; i++) {
                const image_achievment = await fetchImage(res.images[i]);

                if (image_achievment) images[i] = {
                    value: '',
                    _id: image_achievment._id,
                    prev: image_achievment.image
                }
            }
        }


        const new_form = {
            _id: achievment._id,

            name_fr: res.name_fr || '',
            name_en: res.name_en || '',
            name_sp: res.name_sp || '',
            name_de: res.name_de || '',

            text_fr: res.text_fr || '',
            text_en: res.text_en || '',
            text_sp: res.text_sp || '',
            text_de: res.text_de || '',

            parentId: res.parentId || '',
            images: images,
        }

        dispatch(setLoader(-1));

        setFormData(new_form);
        setLastData(new_form);
    }

    return (
        <div className='achievment_selector'>
            {(categories.length !== 0 || achievments.length !== 0) &&
                <ul>
                    {path &&
                        <li className='li_blue'>
                            <button
                                type='button'
                                className='path'
                                onClick={() => setPath(null)}
                            >
                                Revenir en arrière
                            </button>
                        </li>
                    }

                    {categories.length !== 0 && categories.map((category, idx) =>
                        <li key={idx} className={path ? 'li_yellow' : 'li_blue'}>
                            <button
                                type='button'
                                className='path'
                                onClick={() => goPath(category)}
                            >{category.name_fr}</button>
                        </li>
                    )}

                    {achievments.length !== 0 && achievments.map((achievment, idx) =>
                        <li key={idx} className={path ? 'li_red' : 'li_blue'}>
                            <button
                                type='button'
                                className='path'
                                onClick={() => goPath(achievment)}
                            >{achievment.name_fr}</button>

                            {formData &&
                                <button
                                    type='button'
                                    className='check_box'
                                    onClick={() => selectAchievment(achievment)}
                                >
                                    {formData.parentId === achievment._id && <i>✔</i>}
                                </button>
                            }

                            {!formData &&
                                <button
                                    type='button'
                                    className='btn_delete'
                                    onClick={() => selectAchievment(achievment)}
                                >
                                    <img src={trash} alt="X" />
                                </button>
                            }
                        </li>
                    )}
                </ul>
            }

            {categories.length === 0 && achievments.length === 0 &&
                <ul className='void_list'>
                    {path &&
                        <li className='li_blue'>
                            <button
                                type='button'
                                className='path'
                                onClick={() => setPath(null)}
                            >
                                Revenir en arrière
                            </button>
                        </li>
                    }
                    <li className='no_value'>Aucune données</li>
                </ul>
            }
        </div>
    )
}

export default AchievmentSelector;