import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../redux";

export const useGetReferences = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const token = useSelector(state => state.user.token);

    const getReferences = async (prodId) => {
        dispatch(setLoader(1));

        const req = await fetch(`https://api.pro-vide.eu/admin/reference/${prodId}`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const res = await req.json();
        
        dispatch(setLoader(-1));

        if (req.status === 200 && res) return res

        window.scrollTo(0, 0);
        navigate(req.status === 401 ? '/login' : '/error');

        return []
    };

    return getReferences;
};
