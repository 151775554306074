import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProductsToState, setLoader } from '../../redux';

import { usePostFile } from '../../hooks/usePostFile';
import { usePostImage } from '../../hooks/usePostImage';
import { usePageIsEmpty } from '../../hooks/usePageIsEmpty';

import Lang from './../../selectors/Lang';
import NameAndText from '../../components/NameAndText';
import InputImages from '../../components/InputImages';
import BenefitsAndFeatures from '../../components/BenefitsAndFeatures';
import SelecteAssociated from '../../selectors/Associated';
import ParentSelector from '../../selectors/Parent';

import "./style.css"
import AlertMessage from '../../components/AlertMessage';

const langs = ['fr', 'en', 'sp', 'de'];

const init_form = {
    name_fr: '',
    name_en: '',
    name_sp: '',
    name_de: '',

    text_fr: '',
    text_en: '',
    text_sp: '',
    text_de: '',

    images: Array(9).fill({ value: '', prev: null }),

    benefits_fr: Array(5).fill(''),
    benefits_en: Array(5).fill(''),
    benefits_sp: Array(5).fill(''),
    benefits_de: Array(5).fill(''),

    features_fr: Array(5).fill(''),
    features_en: Array(5).fill(''),
    features_sp: Array(5).fill(''),
    features_de: Array(5).fill(''),

    references: [],

    ref_descrip_fr: '',
    ref_descrip_en: '',
    ref_descrip_sp: '',
    ref_descrip_de: '',

    files: {
        fr: [],
        en: [],
        sp: [],
        de: [],
    },

    associated: [],
    parentId: ''
}


function CreateProducts() {
    const dispatch = useDispatch();
    const pageIsEmpty = usePageIsEmpty();

    const postFile = usePostFile();
    const postImage = usePostImage();

    const process = useSelector(state => state.process);
    const token = useSelector(state => state.user.token);
    const products_db = useSelector(state => state.data.products);
    const categories_db = useSelector(state => state.data.categories);

    const [selectParent, setSelectParent] = useState(false);
    const [formData, setFormData] = useState(init_form);
    const [alertMsg, setAlert] = useState([]);

    useEffect(() => {
        if (!formData) return
        if (formData && formData.name_fr !== '' && !process.pageIsEmpty) return

        pageIsEmpty(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const submitForm = async (event) => {
        event.preventDefault();

        if (!formData) return

        if (formData.name_fr === '') {
            setAlert([...alertMsg, {
                title: 'Champ manquant',
                message: 'Le champ "Nom du produit" est requis au bon fonctionnement de votre site'
            }]);
            return
        }

        if (formData.parentId === '') {
            setAlert([...alertMsg, {
                title: 'Catégorie manquante',
                message: 'Vous n\'avez pas renseigné la "Catégorie parente" celle-ci est requis au bon fonctionnement de votre site'
            }]);
            return
        }

        await postProduct();
    }

    const postProduct = async () => {
        const category_exist = categories_db.filter(prod => prod.parentId === formData.parentId);
        if (category_exist.length !== 0) {
            setAlert([...alertMsg, {
                title: 'Catégorie parente invalide',
                message: `La catégorie parente selectionné contient déja une ou plusieurs catégorie(s)`
            }]);
            return
        }

        dispatch(setLoader(1));

        let body = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,

            images: [],

            associated: formData.associated,

            ref_descrip_fr: formData.ref_descrip_fr || '',
            ref_descrip_en: formData.ref_descrip_en || '',
            ref_descrip_sp: formData.ref_descrip_sp || '',
            ref_descrip_de: formData.ref_descrip_de || '',

            parentId: formData.parentId,
        }

        body.images = await postImages();
        body.files = await postFiles();

        for (let lang of langs) {
            body[`benefits_${lang}`] = formData[`benefits_${lang}`].filter(e => e !== '' && e !== ' ');
            body[`features_${lang}`] = formData[`features_${lang}`].filter(e => e !== '' && e !== ' ');
        }

        const req = await fetch(`https://api.pro-vide.eu/admin/product`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        dispatch(setLoader(-1));

        if ([404, 401, 400].includes(req.status)) {
            setAlert([...alertMsg, {
                title: 'Une erreur c\'est produite',
                message: res.message
            }]);
            return
        }
        if (req.status !== 201 || !res) {
            setAlert([...alertMsg, {
                title: 'Serveur indisponible',
                message: res.message
            }]);
            return
        }

        const new_products_db = [...products_db, { ...res, name: res.name_fr }];
        dispatch(addProductsToState(new_products_db));

        setFormData({ ...init_form, parentId: formData.parentId });
        setSelectParent(false);
        setAlert([...alertMsg, {
            title: 'Produit ajouté',
            message: 'Votre produit a bien été ajouté à la base de donnée'
        }]);
    }

    const postImages = async () => {
        let response = [];

        for (let img of formData.images) {
            if (img.value === '') continue

            const formImage = new FormData();
            formImage.append('image', img.value);

            const image = await postImage(setAlert, formImage);
            if (!image) continue
            response.push(image);
        }
        return response
    }

    const postFiles = async () => {
        let response = {
            fr: [],
            en: [],
            sp: [],
            de: []
        };

        for (let lang of langs) {
            for (let file of formData.files[lang]) {
                const formFile = new FormData();
                formFile.append('file', file);

                const file_id = await postFile(setAlert, formFile);
                if (!file_id) continue
                response[lang].push(file_id);
            }
        }
        return response
    }

    return (
        <div className='modal modal_create_products'>
            {!selectParent && <Lang />}

            <form onSubmit={submitForm}>
                {!selectParent &&
                    <>
                        <h1>Ajouter un produit</h1>

                        <section className='sect_name_text_images'>
                            <NameAndText formData={formData} setFormData={setFormData} placeholder='du produit' />
                            <InputImages formData={formData} setFormData={setFormData} placeholder='du produit' />
                        </section>

                        <BenefitsAndFeatures formData={formData} setFormData={setFormData} />
                        <SelecteAssociated formData={formData} setFormData={setFormData} />

                        {!process.pageIsEmpty &&
                            <button
                                type='button'
                                className='btn_next'
                                onClick={() => setSelectParent(true)}
                            >Suivant</button>
                        }
                    </>
                }

                {selectParent &&
                    <ParentSelector
                        formData={formData}
                        setFormData={setFormData}
                        options={{
                            placeholder: 'du produit',
                            type: 'prod'
                        }}
                    />
                }

                {selectParent &&
                    <div className="panel">
                        <button className='btn_return' onClick={() => setSelectParent(false)}>Retour</button>
                        {formData.parentId !== '' && <button className='btn_submit'>Valider</button>}
                    </div>
                }
            </form>

            {alertMsg.length !== 0 && <AlertMessage alertMsg={alertMsg} setAlert={setAlert} />}
        </div>
    )
}

export default CreateProducts;