import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useFetchImage } from "../../hooks/useFetchImage";
import { useFormatText } from "../../hooks/useFormatText";

import PanelVerif from './../../components/PanelVerif';
import Slider from "../../components/Slider";

import "./style.css"

import logo from './../../assets/img/logo-pro-vide.png';

function VerifCategories() {
    const fetchImage = useFetchImage();
    const formatText = useFormatText();

    const lang = useSelector(state => state.user.lang);
    const token = useSelector(state => state.user.token);
    const categories_db = useSelector(state => state.data.categories);

    const [categories_list, setCategoriesList] = useState([]);
    const [className, setClassName] = useState('cat');
    const [alertMsg, setAlert] = useState(null);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        if (!categories_db || categories_list.length !== 0) return
        main();

        async function main() {
            let new_categoriesList = categories_db.filter(cat => cat.online === false);
            // Créez une copie des objets pour éviter de modifier les objets d'origine
            new_categoriesList = new_categoriesList.map(category => ({ ...category }));

            for (let i = 0; i < new_categoriesList.length; i++) {
                const category = new_categoriesList[i];

                if (!category.image || category.image === '') continue

                const img_category = await fetchImage(category.image);
                new_categoriesList[i].image = img_category.image;
            }

            setCategoriesList(new_categoriesList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories_db])

    const validation = async () => {
        const req = await fetch(`https://api.pro-vide.eu/admin/category/${categories_list[counter]._id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ online: true })
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 401];

        // Erreurs classiques
        if (code.includes(req.status)) setAlert({
            title: 'Une erreur c\'est produite lors de la mise en ligne',
            message: res.message
        });

        // Erreur serveur
        if (req.status === 500 || !res) setAlert({
            title: 'Erreur logique, contactez le développeur',
            message: res.message
        });

        if (req.status !== 200 || !res) return

        if (counter !== 0 && counter === categories_list.length - 1) {
            setCounter(counter - 1);
        }

        const new_list = categories_list.filter(serv => serv._id !== categories_list[counter]._id);
        setCategoriesList(new_list);

        setAlert({
            title: 'Validé',
            message: 'La catégorie à bien été mis en ligne'
        });
    }

    return (
        <div className='modal modal_verif modal_verif_categories'>
            <h1>Validation des catégories</h1>

            <PanelVerif counter={counter} setCounter={setCounter} list={categories_list} validation={validation} />

            {categories_list.length !== 0 &&
                <section className="sect_verif sect_verif_categories">
                    <Slider
                        data={categories_list[counter]}
                        className='categories'
                        btn_type='show_more'
                        img_type='simple'
                    />

                    <button className='onglet_home'>
                        <div className='preview'>
                            <img src={categories_list[counter].image} alt="error_image" />
                        </div>

                        <span>{categories_list[counter][`name_${lang}`]}</span>
                    </button>

                    <div className="description">
                        <h3>{categories_list[counter][`name_${lang}`]}</h3>

                        {categories_list[counter][`text_${lang}`] && categories_list[counter][`text_${lang}`] !== '' &&
                            <p>{formatText(categories_list[counter][`text_${lang}`])}</p>
                        }

                        <button
                            className={className === 'cat' ? 'btn_switch_actif' : 'btn_switch'}
                            onClick={() => setClassName(className === 'prod' ? 'cat' : 'prod')}
                        ><i></i></button>

                        <ul className={`main_menu_${className}`}>
                            {categories_db.filter(cat => !cat.parentId && cat.type === 'prod' && cat.online).map((child, idx) => (
                                <li key={idx}>
                                    <button className='onglet'>
                                        <div className='preview'>
                                            <img src={logo} alt='_' />
                                        </div>

                                        <span>{child[`name_${lang}`]}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
            }

            {alertMsg &&
                <div className="mdl_msg_of_verif">
                    <div className="content">
                        <span>{alertMsg.title}</span>
                        <p>{alertMsg.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default VerifCategories;