import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useFetchImage } from "../../hooks/useFetchImage";
import { useFormatText } from "../../hooks/useFormatText";

import "./style.css"

function VerifAchievments() {
    const fetchImage = useFetchImage();
    const formatText = useFormatText();

    const lang = useSelector(state => state.user.lang);
    const token = useSelector(state => state.user.token);
    const achievments_db = useSelector(state => state.data.achievments);

    const [achievments_list, setAchievmentsList] = useState([]);
    const [counter, setCounter] = useState(null);
    const [alertMsg, setAlert] = useState(null);
    const [slider, setSlider] = useState(0);

    useEffect(() => {
        if (!achievments_db || achievments_list.length !== 0) return
        main();

        async function main() {
            let new_achievmentsList = achievments_db.filter(achiev => achiev.online === false);
            // Créez une copie des objets pour éviter de modifier les objets d'origine
            new_achievmentsList = new_achievmentsList.map(achievment => ({ ...achievment }));

            for (let i = 0; i < new_achievmentsList.length; i++) {
                const achievment = new_achievmentsList[i];
                let images = [];

                if (!achievment.images || achievment.images.length === 0) continue

                for (let j = 0; j < achievment.images.length; j++) {
                    const image = achievment.images[j];

                    const img_achievment = await fetchImage(image);
                    images.push(img_achievment.image);

                }
                new_achievmentsList[i].images = images;
            }

            setAchievmentsList(new_achievmentsList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [achievments_db])

    useEffect(() => {
        if (counter === null) setSlider(0);
    }, [counter])

    const validation = async () => {
        const req = await fetch(`https://api.pro-vide.eu/admin/achievement/${achievments_list[counter]._id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ online: true })
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 401];

        // Erreurs classiques
        if (code.includes(req.status)) setAlert({
            title: 'Une erreur c\'est produite lors de la mise en ligne',
            message: res.message
        });

        // Erreur serveur
        if (req.status === 500 || !res) setAlert({
            title: 'Erreur logique, contactez le développeur',
            message: res.message
        });

        if (req.status !== 200 || !res) return

        setCounter(null);
        setSlider(0);

        const new_list = achievments_list.filter(serv => serv._id !== achievments_list[counter]._id);
        setAchievmentsList(new_list);

        setAlert({
            title: 'Validé',
            message: 'La réalisation à bien été mis en ligne'
        });
    }

    const prev = () => {
        setSlider(slider === 0 ? achievments_list[counter].images.length - 1 : slider - 1);
    }
    
    const next = () => {
        setSlider(slider === achievments_list[counter].images.length - 1 ? 0 : slider + 1);
    }    

    return (
        <div className='modal modal_verif modal_verif_achievments'>
            <h1>Validation des réalisations</h1>

            {achievments_list.length !== 0 &&
                <section className='sect_verif sect_verif_achievments'>
                    {achievments_list.map((achiev, idx) => (
                        <li
                            key={idx}
                            onClick={() => setCounter(idx)}
                        >
                            <div className="container-img">
                                {achiev.images.length !== 0 && <img src={achiev.images[0]} alt="error_image" />}
                            </div>

                            <p>{achiev[`name_${lang}`]}</p>
                        </li>
                    ))}
                </section>
            }

            {typeof counter === 'number' &&
                <div className='mdl_show_achievment'>
                    <div className="content">
                        <button onClick={() => setCounter(null)} className='btn_close'>
                            <i className='fas fa-xmark'></i>
                        </button>

                        <div className="slider_achievment">
                            {achievments_list[counter].images.length > 1 && <i onClick={() => prev()} className="arrow arrow_left">{'<'}</i>}
                            {achievments_list[counter].images.length !== 0 && <img src={achievments_list[counter].images[slider]} alt="error_image" />}
                            {achievments_list[counter].images.length > 1 && <i onClick={() => next()} className="arrow arrow_right">{'>'}</i>}
                        </div>

                        <div className="descrip">
                            <h2>{achievments_list[counter][`name_${lang}`]}</h2>
                            <p>{formatText(achievments_list[counter][`text_${lang}`])}</p>
                        </div>

                        <button onClick={() => validation()} className='btn_valid'>Valider</button>
                    </div>
                </div>
            }

            {alertMsg &&
                <div className="mdl_msg_of_verif">
                    <div className="content">
                        <span>{alertMsg.title}</span>
                        <p>{alertMsg.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default VerifAchievments;