export const useFormatFilename = () => {
    const formatFilename = (name) => {
        // Supprime tout ce qui se trouve entre 'https' et 'plans/' ou 'images/'
        const regex1 = /https.*?(plans|images)\//;
        let result = name.replace(regex1, '');

        // Extrait l'extension du fichier
        const regexExtension = /\.([^.]+)$/;
        const match = result.match(regexExtension);
        const extension = match ? match[1] : '';

        // Supprime les numéros aléatoires à la fin du nom de fichier
        if (extension) {
            const regex2 = new RegExp(`-\\d+\\.${extension}$`);
            result = result.replace(regex2, `.${extension}`);
        }
        return result;
    };

    return formatFilename;
};