import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addServicesToState } from "../../redux";

import { useSetOrder } from "../../hooks/useSetOrder";
import { useFetchImage } from '../../hooks/useFetchImage';

import AlertMessage from '../../components/AlertMessage';

import "./style.css"

import icon_return from '../../assets/img/icon_return.png'
import icon_return_color from '../../assets/img/icon_return_color.png'

function ModalServices() {
    const dispatch = useDispatch();
    const setOrder = useSetOrder();
    const fetchImage = useFetchImage();

    const [categories_list, setCategories] = useState([]);
    const [services_list, setServices] = useState([]);
    const [alertMsg, setAlert] = useState([]);
    const [path, setPath] = useState(null);

    const categories_db = useSelector(state => state.data.categories);
    const services_db = useSelector(state => state.data.services);

    useEffect(() => {
        if (!categories_db) return
        setCategories([]);
        setServices([]);

        const fetchImages = async (datas, setList, type) => {
            let new_list = [];

            if (type === 'simple') {
                for (let data of datas) {
                    let new_data_list = { ...data }
    
                    if (data.image) {
                        const image = await fetchImage(data.image);
                        new_data_list.image = image;
                    }
                    new_list.push(new_data_list);
                }
            }

            if (type === 'multi') {
                for (let data of datas) {
                    let new_data_list = { ...data }
    
                    if (data.images.length !== 0) {
                        const image = await fetchImage(data.images[0]);
                        new_data_list.image = image;
                    }
                    new_list.push(new_data_list);
                }
            }
            setList(new_list);
        }

        const categories = categories_db.filter(cat => cat.type === 'serv' && (path ? cat.parentId === path : !cat.parentId));

        if (categories.length !== 0) {
            fetchImages(categories, setCategories, 'simple');
            return
        }

        if (!path) return

        const services = services_db.filter(serv => serv.parentId === path);
        if (services.length !== 0) {
            fetchImages(services, setServices, 'multi');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories_db, services_db, path])

    const previous = () => {
        const parent = categories_db.find(cat => cat._id === path);
        setPath(parent.parentId);
    }

    const goTo = (_id) => {
        const childrens = categories_db.filter(cat => cat.type === 'serv' && cat.parentId === _id);
        const childrensServ = services_db.filter(serv => serv.parentId === _id);
        if (childrens.length !== 0 || childrensServ.length !== 0) setPath(_id);
    }

    const changePosPrev = async (service) => {
        if (service.order === 0) return;
    
        const prev_element = services_list.find(achiev => achiev.order === service.order - 1);
    
        if (!prev_element) {
            setAlert([...alertMsg, {
                title: `Erreur`,
                message: 'Contactez le développeur'
            }]);
            return;
        }
    
        const response = await setOrder('service', service._id, prev_element._id, setAlert);
        if (response !== true) return;
    
        const updatedServices_db = services_db.map(achiev => {
            if (achiev._id === service._id) {
                return { ...achiev, order: achiev.order - 1 };
            } 
            else if (achiev._id === prev_element._id) {
                return { ...achiev, order: achiev.order + 1 };
            }
            return achiev;
        });
        dispatch(addServicesToState(updatedServices_db.sort((a, b) => a.order - b.order)));
    
        const updatedServices_list = services_list.map(achiev => {
            if (achiev._id === service._id) {
                return { ...achiev, order: achiev.order - 1 };
            } 
            else if (achiev._id === prev_element._id) {
                return { ...achiev, order: achiev.order + 1 };
            }
            return achiev;
        });
        setServices(updatedServices_list.sort((a, b) => a.order - b.order));
    }

    const changePosNext = async (service) => {
        if (service.order === services_list.length - 1) return;
    
        const next_element = services_list.find(achiev => achiev.order === service.order + 1);
    
        if (!next_element) {
            setAlert([...alertMsg, {
                title: `Erreur`,
                message: 'Contactez le développeur'
            }]);
            return;
        }
    
        const response = await setOrder('service', service._id, next_element._id, setAlert);
        if (response !== true) return;
    
        const updatedServices_db = services_db.map(achiev => {
            if (achiev._id === service._id) {
                return { ...achiev, order: achiev.order + 1 };
            } 
            else if (achiev._id === next_element._id) {
                return { ...achiev, order: achiev.order - 1 };
            }
            return achiev;
        });
        dispatch(addServicesToState(updatedServices_db.sort((a, b) => a.order - b.order)));
    
        const updatedServices_list = services_list.map(achiev => {
            if (achiev._id === service._id) {
                return { ...achiev, order: achiev.order + 1 };
            } 
            else if (achiev._id === next_element._id) {
                return { ...achiev, order: achiev.order - 1 };
            }
            return achiev;
        });
        setServices(updatedServices_list.sort((a, b) => a.order - b.order));
    }

    return (
        <div className='modal modal_show modal_services'>
            <h1>Mes services</h1>

            {categories_list.length !== 0 &&
                <ul className="categories_list_order">
                    {path &&
                        <li className="onglet_return_category">
                            <button
                                className='onglet_categories'
                                onClick={() => previous()}
                            >
                                <div className='preview'>
                                    <img
                                        src={icon_return}
                                        alt='Illustration'
                                    />
                                </div>

                                <span>Retour en arrière</span>
                            </button>
                        </li>
                    }

                    {categories_list.map((cat, idx) =>
                        <li className="ctn_onglet_category" key={idx}>
                            <button
                                className='onglet_categories'
                                onClick={() => goTo(cat._id)}
                            >
                                <div className='preview'>
                                    <img
                                        src={cat && cat.image && cat.image.image}
                                        alt='Illustration'
                                    />
                                </div>

                                <span>{cat.name_fr}</span>
                            </button>
                        </li>
                    )}
                </ul>
            }

            {services_list.length !== 0 &&
                <ul className="services_list_order">
                    {path &&
                        <li className="onglet_return_service">
                            <button
                                className='onglet_services'
                                onClick={() => previous()}
                            >
                                <div className='preview'>
                                    <img
                                        src={icon_return_color}
                                        alt='Illustration'
                                    />
                                </div>

                                <span>Retour en arrière</span>
                            </button>
                        </li>
                    }

                    {services_list.map((serv, idx) =>
                        <li className="ctn_onglet_service" key={idx}>
                            <button
                                className="btn_prev"
                                onClick={() => changePosPrev(serv)}
                            >
                                <i className="fas fa-chevron-left"></i>
                            </button>

                            <button className='onglet_services'>
                                <div className='preview'>
                                    <img
                                        src={serv && serv.image && serv.image.image}
                                        alt='Illustration'
                                    />
                                </div>

                                <span>{serv.name_fr}</span>
                            </button>
                            
                            <button
                                className="btn_next"
                                onClick={() => changePosNext(serv)}
                            >
                                <i className="fas fa-chevron-right"></i>
                            </button>
                        </li>
                    )}
                </ul>
            }

            {alertMsg.length !== 0 && <AlertMessage alertMsg={alertMsg} setAlert={setAlert} />}
        </div>
    )
}

export default ModalServices;