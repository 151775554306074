import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetReferences } from '../../hooks/useGetReferences';

import "./style.css"


function SearchBar({ search, setSearch, setFormData, type }) {
    const getReferences = useGetReferences();

    const products_db = useSelector(state => state.data.products);

    const [searchList, setSearchList] = useState([]);
    const [focus, setIsFocus] = useState(false);

    const init_form = {
        head: ['References', '3D-plan'],
        references: Array.from({ length: type === 'create' ? 4 : 0 }, () => ({ References: '', '3D-plan': '' }))
    };

    useEffect(() => {
        let new_list = [];

        if (!search || search.value === '') {
            setSearchList(new_list);
            return
        }

        // Recherche de produit par leur name_fr
        if (type === 'create') {
            new_list = products_db.filter(prod => prod.references && prod.references.data.length === 0 && prod.name_fr.toLowerCase().includes(search.value.toLowerCase()));
        }
        else {
            new_list = products_db.filter(prod => prod.references && prod.references.data.length !== 0 && prod.name_fr.toLowerCase().includes(search.value.toLowerCase()));
        }

        // Limiter le nombre de résultats à 16
        new_list = new_list.slice(0, 16);

        setSearchList(new_list);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products_db, search])

    const searchProduct = (e) => {
        setSearch({ id: '', value: e.target.value });
        if (type !== 'create') setFormData(init_form);
    }

    const selectProduct = async (prod) => {
        setSearch({ id: prod._id, value: prod.name_fr });
        if (type === 'create') return
        
        const references = await getReferences(prod._id);

        const new_form = {
            head: references.length > 0 ? Object.keys(references[0].table) : [],
            references: references.length > 0 ?
                references.map(reference => ({
                    _id: reference._id,
                    ...reference.table
                })) :
                Array.from({ length: 4 }, () => ({
                    References: '',
                    '3D-plan': ''
                })),
        };
        setFormData(new_form);
    };


    return (
        <div className="ctn_search">
            <div className="ctn_search_bar">
                <h2>Produit :</h2>

                <div className="search_bar">
                    <input
                        type="text"
                        onFocus={() => setIsFocus(true)}
                        onBlur={() => setTimeout(() => setIsFocus(false), 200)}

                        placeholder="Recherche par nom : 'Hublot démontable'"
                        onChange={(e) => searchProduct(e)}
                        value={search.value}
                    />

                    <i className="fas fa-magnifying-glass"></i>
                </div>

                {focus && searchList.length !== 0 && (
                    <ul className="search_list">
                        {searchList.map((prod, idx) => (
                            <li key={idx}>
                                <p>{prod.name_fr}</p>

                                <button onClick={() => selectProduct(prod)}>
                                    <i className="fas fa-share"></i>
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}

export default SearchBar;