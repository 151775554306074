import "./style.css"

function AlertMessage({ alertMsg, setAlert }) {
    const validation = () => {
        setAlert(alertMsg.filter((msg, idx) => idx !== 0));
    }
    

    return alertMsg.length !== 0 && (
        <div className="mdl_msg_of_verif">
            <div className="content">
                <span>{alertMsg[0].title}</span>
                <p>{alertMsg[0].message}</p>

                <div className="panel">
                    <button type='button' onClick={() => validation()}>Compris</button>
                </div>
            </div>
        </div>
    )
}

export default AlertMessage;