import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../redux";

export const usePostImage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const token = useSelector(state => state.user.token);

    const postImage = async (setAlert, image) => {
        dispatch(setLoader(1));

        const req = await fetch(`https://api.pro-vide.eu/admin/add-img`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}` },
            body: image
        });
        const res = await req.json();

        dispatch(setLoader(-1));

        if (req.status === 401) navigate('/login');
        if ([404, 400].includes(req.status)) setAlert(prev => [...prev, {
            title: 'Erreur lors l\'ajout de l\'image',
            message: res.message
        }]);
        if (req.status === 500 || !res) setAlert(prev => [...prev, {
            title: 'Erreur logique, contactez le développeur',
            message: res.message
        }]);
        return res ? res : null
    };

    return postImage;
};
