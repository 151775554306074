import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProductsToState, setLoader } from '../../redux';

import { usePostImage } from '../../hooks/usePostImage';
import { usePostFile } from '../../hooks/usePostFile';

import { useDeleteFile } from '../../hooks/useDeleteFile';
import { useDeleteImage } from '../../hooks/useDeleteImage';

import Lang from './../../selectors/Lang';
import ProductSelector from '../../selectors/Product';
import NameAndText from '../../components/NameAndText';
import InputImages from '../../components/InputImages';
import BenefitsAndFeatures from '../../components/BenefitsAndFeatures';
import SelecteAssociated from '../../selectors/Associated';
import AlertMessage from '../../components/AlertMessage';

import "./style.css"

const langs = ['fr', 'en', 'sp', 'de'];

const init_form = {
    name_fr: '',
    name_en: '',
    name_sp: '',
    name_de: '',

    text_fr: '',
    text_en: '',
    text_sp: '',
    text_de: '',

    images: Array(9).fill({ value: '', prev: null }),

    benefits_fr: Array(5).fill(''),
    benefits_en: Array(5).fill(''),
    benefits_sp: Array(5).fill(''),
    benefits_de: Array(5).fill(''),

    features_fr: Array(5).fill(''),
    features_en: Array(5).fill(''),
    features_sp: Array(5).fill(''),
    features_de: Array(5).fill(''),

    references: [],

    ref_descrip_fr: '',
    ref_descrip_en: '',
    ref_descrip_sp: '',
    ref_descrip_de: '',

    files: {
        fr: [],
        en: [],
        sp: [],
        de: [],
    },

    associated: [],
    parentId: ''
}


function UpdateProducts() {
    const dispatch = useDispatch();

    const postFile = usePostFile();
    const postImage = usePostImage();

    const deleteFile = useDeleteFile();
    const deleteImage = useDeleteImage();

    const token = useSelector(state => state.user.token);
    const products_db = useSelector(state => state.data.products);

    const [formData, setFormData] = useState(init_form);
    const [lastData, setLastData] = useState(null);
    const [alertMsg, setAlert] = useState([]);

    const submitForm = async (event) => {
        event.preventDefault();
        if (!formData) return

        if (formData.name_fr === '') {
            setAlert([...alertMsg, {
                title: 'Champ manquant',
                message: 'Le champ "Nom du produit" est requis au bon fonctionnement de votre site'
            }]);
            return
        }
        await updateProduct();
    }

    const updateProduct = async () => {
        dispatch(setLoader(1));

        let body = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,

            associated: formData.associated,

            ref_descrip_fr: formData.ref_descrip_fr || '',
            ref_descrip_en: formData.ref_descrip_en || '',
            ref_descrip_sp: formData.ref_descrip_sp || '',
            ref_descrip_de: formData.ref_descrip_de || '',

            files: {
                fr: [],
                en: [],
                sp: [],
                de: []
            },
        }

        const images = await updateImages();
        body.images = images;

        body.files.fr = await updateFiles('fr');
        body.files.en = await updateFiles('en');
        body.files.sp = await updateFiles('sp');
        body.files.de = await updateFiles('de');

        for (let lang of langs) {
            body[`benefits_${lang}`] = formData[`benefits_${lang}`].filter(e => e !== '' && e !== ' ');
            body[`features_${lang}`] = formData[`features_${lang}`].filter(e => e !== '' && e !== ' ');
        }

        const req = await fetch(`https://api.pro-vide.eu/admin/product/${formData._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        dispatch(setLoader(-1));

        if ([404, 401, 400].includes(req.status)) setAlert([...alertMsg, {
            title: 'Une erreur c\'est produite lors de la modification du produit',
            message: res.message
        }]);

        if (req.status === 500) setAlert([...alertMsg, {
            title: 'Erreur logique, contactez le développeur',
            message: res.message
        }]);

        if (req.status !== 200 || !res) return

        let new_products_db = [];

        // Mise à jour du state 'products'
        for (let product of products_db) {
            if (product._id !== formData._id) {
                new_products_db.push(product);
                continue
            };

            new_products_db.push({
                ...body,
                _id: formData._id,
                name: body.name_fr,
                parentId: formData.parentId
            })
        }

        dispatch(addProductsToState(new_products_db));

        setFormData({ ...init_form });
        setLastData(null);

        setAlert([...alertMsg, {
            title: 'Produit modifié',
            message: 'Votre produit a bien été modifié'
        }]);
    }

    const updateImages = async () => {
        let response = [];

        for (let i = 0; i < formData.images.length; i++) {
            const image_last = lastData.images[i] || null;
            const image = formData.images[i];

            // Si l'image reste inchangé
            if (image._id) {
                response.push(image._id);
                continue
            }

            // Si il n'y a pas et n'avait pas d'image
            if (!image_last._id && !image._id && image.value === '') continue

            // Si l'ancienne image a été supprimé
            if (image_last._id && !image._id) {
                const image_delete = await deleteImage(image_last._id, setAlert);

                if (!image_delete) {
                    setAlert([...alertMsg, {
                        title: 'Problème d\'image',
                        message: `Un problème est survenue lors de la suppression de la précédente image n° ${i}'`
                    }]);
                    response.push(image_last._id);
                    continue
                }
                if (image.value === '') continue
            }

            // Si une nouvelle image est ajouté
            if (image.value !== '') {
                const formImage = new FormData();
                formImage.append('image', formData.images[i].value);

                // Ajout de l'image à la db
                const image_id = await postImage(setAlert, formImage);

                // Si l'image n'est pas ajouté on continue
                if (!image_id) {
                    setAlert([...alertMsg, {
                        title: 'Problème d\'image',
                        message: `Un problème est survenue lors de la ajout de l'image n° ${i}`
                    }]);
                    continue
                }
                response.push(image_id)
            }
        }
        return response
    }

    const updateFiles = async (lg) => {
        let response = []

        const files = formData.files[lg];
        const last_files = lastData.files[lg];

        // Suppression de tous les fichiers à retirer
        for (let i = 0; i < last_files.length; i++) {
            if (!files.includes(last_files[i])) {
                const file_delete = await deleteFile(last_files[i]._id, setAlert);

                if (!file_delete) {
                    setAlert([...alertMsg, {
                        title: 'Problème de fichier',
                        message: `Un problème est survenue lors de la suppression du précédent fichier n° ${i} version '${lg}'`
                    }]);
                    response.push(last_files[i]._id);
                    continue
                }
            }
        }

        // Ajout des images pré-existante & Ajout de nouveau fichiers
        for (let i = 0; i < files.length; i++) {
            if (files[i]._id) {
                response.push(files[i]._id);
                continue
            }

            const formFile = new FormData();
            formFile.append('file', formData.files[lg][i]);

            const file_id = await postFile(setAlert, formFile);
            response.push(file_id);
        }

        return response
    }

    const reload = () => {
        setFormData(init_form);
        setLastData(null);
    }

    return (
        <div className='modal modal_update_products'>
            {lastData && <Lang />}

            <form onSubmit={submitForm}>
                <h1>Modifier un produit</h1>

                {!lastData &&
                    <ProductSelector
                        formData={formData}
                        setFormData={setFormData}
                        setLastData={setLastData}
                    />
                }

                {lastData &&
                    <section className='sect_name_text_images'>
                        <NameAndText formData={formData} setFormData={setFormData} placeholder='du produit' />
                        <InputImages formData={formData} setFormData={setFormData} placeholder='du produit' />
                    </section>
                }

                {lastData &&
                    <BenefitsAndFeatures formData={formData} setFormData={setFormData} />
                }
                {lastData &&
                    <SelecteAssociated formData={formData} setFormData={setFormData} />
                }

                {lastData &&
                    <div className="panel">
                        <button className='btn_return' onClick={() => reload()}>Retour</button>
                        <button className='btn_update'>Modifier</button>
                    </div>
                }
            </form>

            {alertMsg.length !== 0 && <AlertMessage alertMsg={alertMsg} setAlert={setAlert} />}
        </div>
    )
}

export default UpdateProducts;