import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAchievmentsToState, setLoader } from '../../redux';

import { usePostImage } from '../../hooks/usePostImage';
import { usePageIsEmpty } from '../../hooks/usePageIsEmpty';

import Lang from './../../selectors/Lang';
import NameAndText from '../../components/NameAndText';
import InputImages from '../../components/InputImages';
import ParentSelector from '../../selectors/Parent';

import "./style.css"
import AlertMessage from '../../components/AlertMessage';

const init_form = {
    name_fr: '',
    name_en: '',
    name_sp: '',
    name_de: '',

    text_fr: '',
    text_en: '',
    text_sp: '',
    text_de: '',

    images: Array(9).fill({ value: '', prev: null }),

    parentId: '',
}

function CreateAchievments() {
    const dispatch = useDispatch();
    const pageIsEmpty = usePageIsEmpty();

    const postImage = usePostImage();

    const process = useSelector(state => state.process);
    const token = useSelector(state => state.user.token);
    const achievments_db = useSelector(state => state.data.services);

    const [selectParent, setSelectParent] = useState(false);
    const [formData, setFormData] = useState(init_form);
    const [alertMsg, setAlert] = useState([]);

    useEffect(() => {
        if (!formData) return
        if (formData && formData.name_fr !== '' && !process.pageIsEmpty) return

        pageIsEmpty(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const submitForm = async (event) => {
        event.preventDefault();

        if (!formData) return

        if (formData.name_fr === '') {
            setAlert([...alertMsg, {
                title: 'Champ manquant',
                message: 'Le champ "Nom de la réalisation" est requis au bon fonctionnement de votre site'
            }]);
            return
        }

        if (formData.parentId === '') {
            setAlert([...alertMsg, {
                title: 'Catégorie manquante',
                message: 'Vous n\'avez pas renseigné la "Catégorie parente" celle-ci est requis au bon fonctionnement de votre site'
            }]);
            return
        }

        await postAchievment();
    }

    const postAchievment = async () => {
        dispatch(setLoader(1));

        let body = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,

            images: [],

            parentId: formData.parentId,
        }

        body.images = await postImages();

        const req = await fetch(`https://api.pro-vide.eu/admin/achievement`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        dispatch(setLoader(-1));

        if ([404, 401, 400].includes(req.status)) {
            setAlert([...alertMsg, {
                title: 'Une erreur c\'est produite',
                message: res.message
            }]);
            return
        }
        if (req.status !== 201 || !res) {
            setAlert([...alertMsg, {
                title: 'Serveur indisponible',
                message: res.message
            }]);
            return
        }

        const new_achievments_db = [...achievments_db, { ...res, name: res.name_fr }];
        dispatch(addAchievmentsToState(new_achievments_db));

        setFormData({ ...init_form, parentId: formData.parentId });
        setSelectParent(false);
        setAlert([...alertMsg, {
            title: 'Réalisation ajouté',
            message: 'Votre réalisation a bien été ajouté à la base de donnée'
        }]);
    }

    const postImages = async () => {
        let response = [];

        for (let img of formData.images) {
            if (img.value === '') continue

            const formImage = new FormData();
            formImage.append('image', img.value);

            const image = await postImage(setAlert, formImage);
            if (!image) continue
            response.push(image);
        }

        return response
    }

    return (
        <div className='modal modal_create_achievments'>
            {!selectParent && <Lang />}

            <form onSubmit={submitForm}>
                {!selectParent &&
                    <>
                        <h1>Ajouter une réalisation</h1>

                        <section className='sect_name_text_images'>
                            <NameAndText formData={formData} setFormData={setFormData} placeholder='de la réalisation' />
                            <InputImages formData={formData} setFormData={setFormData} placeholder='de la réalisation' />
                        </section>

                        {!process.pageIsEmpty &&
                            <button
                                type='button'
                                className='btn_next'
                                onClick={() => setSelectParent(true)}
                            >Suivant</button>
                        }
                    </>
                }

                {selectParent &&
                    <ParentSelector
                        formData={formData}
                        setFormData={setFormData}
                        options={{
                            placeholder: 'de la réalisation',
                            type: 'all'
                        }}
                        lock={true}
                    />
                }

                {selectParent &&
                    <div className="panel">
                        <button className='btn_return' onClick={() => setSelectParent(false)}>Retour</button>
                        {formData.parentId !== '' && <button className='btn_submit'>Valider</button>}
                    </div>
                }
            </form>

            {alertMsg.length !== 0 && <AlertMessage alertMsg={alertMsg} setAlert={setAlert} />}
        </div>
    )
}

export default CreateAchievments;