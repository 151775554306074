import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCategoriesToState, setLoader } from '../../redux';

import CategorySelector from '../../selectors/Category';
import ConfirmDelete from '../../components/ConfirmDelete';
import AlertMessage from '../../components/AlertMessage';

import "./style.css"

function DeleteCategories() {
    const dispatch = useDispatch();

    const [type, setType] = useState('prod');
    const [alertMsg, setAlert] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [toDelete, setToDelete] = useState(null);

    const token = useSelector(state => state.user.token);

    const products_db = useSelector(state => state.data.products);
    const services_db = useSelector(state => state.data.services);
    const categories_db = useSelector(state => state.data.categories);
    const achievments_db = useSelector(state => state.data.achievments);

    useEffect(() => {
        if (!toDelete || !confirm) return

        if (!childrenVerification()) {
            setToDelete(null);
            setConfirm(false);
            return
        }

        dispatch(setLoader(1));
        deleteCategory();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDelete, confirm])

    const childrenVerification = () => {
        let response = true;

        const variants = [
            {
                db: categories_db,
                alert: 'une ou plusieurs sous-catégorie(s)'
            },
            {
                db: products_db,
                alert: 'un ou plusieurs produit(s)'
            },
            {
                db: services_db,
                alert: 'un ou plusieurs service(s)'
            },
            {
                db: achievments_db,
                alert: 'une ou plusieurs réalisation(s)'
            }
        ]

        for (let variant of variants) {
            if (variant.db.find(e => e.parentId === toDelete._id)) {
                setAlert([...alertMsg, {
                    title: `Imposible de supprimer cette catégorie`,
                    message: `Vous ne pouvez pas supprimer cette catégorie car elle possède ${variant.alert}`
                }]);
                response = false
            }
        }
        return response
    }

    const deleteCategory = async () => { 
        const req = await fetch(`https://api.pro-vide.eu/admin/category/${toDelete._id}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const res = await req.json();

        dispatch(setLoader(-1));

        if ([400, 401, 404].includes(req.status)) {
            setToDelete(null);
            setConfirm(false);

            setAlert([...alertMsg, {
                title: 'Une erreur s\'est produite',
                message: res.message
            }]);
            return;
        }

        if (req.status !== 200 || !res) {
            setToDelete(null);
            setConfirm(false);

            setAlert([...alertMsg, {
                title: 'Serveur indisponible',
                message: res.message
            }]);
            return;
        }

        // Mise à jour du state 'categories'
        const new_categories_db = categories_db.filter(cat => cat._id !== toDelete._id);
        dispatch(addCategoriesToState(new_categories_db));

        setAlert([...alertMsg, {
            title: 'Catégorie supprimé',
            message: `La catégorie '${toDelete.name_fr}' a bien été supprimé.`
        }])

        setToDelete(null);
        setConfirm(false);
    }

    return (
        <div className='modal modal_delete_categories'>
            <button
                type='button'
                className={`select_type_prod ${type === 'prod' ? 'actif' : ''}`}
                onClick={() => setType('prod')}
            >Produit</button>

            <button
                type='button'
                className={`select_type_serv ${type === 'serv' ? 'actif' : ''}`}
                onClick={() => setType('serv')}
            >Service</button>

            <form onSubmit={(event) => event.preventDefault()}>
                <h1>Supprimer une catégorie</h1>

                <CategorySelector setToDelete={setToDelete} type={type} />
            </form>

            {toDelete && !confirm && <ConfirmDelete toDelete={toDelete} setToDelete={setToDelete} setConfirm={setConfirm} />}

            {alertMsg.length !== 0 && <AlertMessage alertMsg={alertMsg} setAlert={setAlert} />}
        </div>
    )
}

export default DeleteCategories;