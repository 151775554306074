import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCategoriesToState, setLoader } from '../../redux';

import { usePostImage } from '../../hooks/usePostImage';
import { usePageIsEmpty } from '../../hooks/usePageIsEmpty';

import Lang from './../../selectors/Lang';
import NameAndText from '../../components/NameAndText';
import ParentSelector from '../../selectors/Parent';
import AlertMessage from '../../components/AlertMessage';

import "./style.css"

import icon_inp_img from './../../assets/svg/svg_image_sombre.svg';
import icon_trash_img from './../../assets/svg/svg_trash_image.svg';

const init_form = {
    type: 'prod',

    name_fr: '',
    name_en: '',
    name_sp: '',
    name_de: '',

    text_fr: '',
    text_en: '',
    text_sp: '',
    text_de: '',

    parentId: '',
    image: {
        value: '',
        prev: null,
    }
}


function CreateCategories() {
    const dispatch = useDispatch();
    const postImage = usePostImage();
    const pageIsEmpty = usePageIsEmpty();

    const process = useSelector(state => state.process);
    const token = useSelector(state => state.user.token);
    const categories_db = useSelector(state => state.data.categories);
    const products_db = useSelector(state => state.data.products);
    const services_db = useSelector(state => state.data.services);

    const [selectParent, setSelectParent] = useState(false);
    const [formData, setFormData] = useState(init_form);
    const [noParent, setNoParent] = useState(false);
    const [alertMsg, setAlert] = useState([]);

    useEffect(() => {
        if (!formData) return
        if (formData && formData.name_fr !== '' && !process.pageIsEmpty) return

        pageIsEmpty(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, process.pageIsEmpty])

    const submitForm = async (event) => {
        event.preventDefault();
        if (!formData) return

        if (formData.name_fr === '') {
            setAlert([...alertMsg, {
                title: 'Champ manquant',
                message: 'Le champ "Nom de la catégorie" est requis au bon fonctionnement de votre site'
            }]);
            return
        }

        if (formData.parentId === '') {
            setNoParent(true)
            return
        }
        await postCategory();
    }

    const postCategory = async () => {
        if (formData.parentId !== '') {
            const product_exist = products_db.filter(prod => prod.parentId === formData.parentId);
            const service_exist = services_db.filter(serv => serv.parentId === formData.parentId);

            if (product_exist.length !== 0 || service_exist.length !== 0) {
                setAlert([...alertMsg, {
                    title: 'Catégorie parente invalide',
                    message: `La catégorie parente selectionné contient déja un ou plusieurs ${formData.type === 'prod' ? 'produit(s)' : 'service(s)'}`
                }]);
                return
            }
        }

        dispatch(setLoader(1));
        setNoParent(false);

        let body = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,

            type: formData.type,
        }

        if (formData.parentId !== '') body.parentId = formData.parentId;

        if (formData.image.value !== '') {
            const formImage = new FormData();
            formImage.append('image', formData.image.value);

            const image = await postImage(setAlert, formImage);
            if (!image) {
                setAlert([...alertMsg, {
                    title: `Erreur ajout d'image`,
                    message: `Impossible d'enregistrer l'image de la catégorie`,
                }])
                dispatch(setLoader(-1));
                return
            }
            body.image = image;
        }

        const req = await fetch(`https://api.pro-vide.eu/admin/category`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        dispatch(setLoader(-1));

        if ([404, 401, 400].includes(req.status)) {
            setAlert([...alertMsg, {
                title: 'Une erreur c\'est produite',
                message: res.message
            }]);
            return
        }
        if (req.status !== 201 || !res) {
            setAlert([...alertMsg, {
                title: 'Serveur indisponible',
                message: res.message
            }]);
            return
        }

        const new_categories_db = [...categories_db, { ...res, name: res.name_fr }];
        dispatch(addCategoriesToState(new_categories_db));

        setFormData({ ...init_form, parentId: formData.parentId });
        setSelectParent(false);
        setAlert([...alertMsg, {
            title: 'Catégorie ajoutée',
            message: 'Votre catégorie a bien été ajouté à la base de donnée'
        }]);
    }

    const changeImage = (e) => {
        const file = e.target.files[0];
        const previewUrl = URL.createObjectURL(file);

        setFormData({
            ...formData,
            image: {
                value: file || '',
                prev: previewUrl || null
            }
        })
    }

    const deleteImage = () => {
        setFormData({
            ...formData,
            image: {
                value: '',
                prev: null
            }
        })
    }

    return (
        <div className='modal modal_create_categories'>
            {!selectParent && <Lang />}

            <form onSubmit={submitForm}>
                {!selectParent &&
                    <>
                        <h1>Ajouter une catégorie</h1>

                        <section className='sect_name_text_images'>
                            <NameAndText formData={formData} setFormData={setFormData} placeholder='de la catégorie' />

                            <div className="input_img">
                                <label>Image de la catégorie :</label>

                                <div className="input_file">
                                    <input type="file" onChange={(e) => changeImage(e)} />

                                    {formData.image.prev &&
                                        <button
                                            type='button'
                                            className='btn_del_image'
                                            onClick={() => deleteImage()}
                                        >
                                            <img src={icon_trash_img} alt=" " />
                                        </button>
                                    }

                                    {formData.image.prev && <img className='preview' src={formData.image.prev} alt='Non supporté' />}

                                    {!formData.image.prev && <img className='icon' src={icon_inp_img} alt=" " />}

                                    {!formData.image.prev &&
                                        <div className="placeholder">
                                            <p>Ajouter une image</p>
                                            <span>(.jpg, .png, .webp)</span>
                                        </div>
                                    }
                                </div>

                                <label>Type de catégorie :</label>

                                <div className="type_category">
                                    <button
                                        type='button'
                                        className={formData.type === 'prod' ? 'actif' : ''}
                                        onClick={() => setFormData({ ...formData, type: 'prod' })}
                                    >Produit</button>

                                    <button
                                        type='button'
                                        className={formData.type === 'serv' ? 'actif' : ''}
                                        onClick={() => setFormData({ ...formData, type: 'serv' })}
                                    >Service</button>
                                </div>
                            </div>
                        </section>

                        {!process.pageIsEmpty &&
                            <button
                                type='button'
                                className='btn_next'
                                onClick={() => setSelectParent(true)}
                            >Suivant</button>
                        }
                    </>
                }

                {selectParent &&
                    <ParentSelector
                        formData={formData}
                        setFormData={setFormData}
                        options={{
                            placeholder: 'de la catégorie',
                            type: formData.type
                        }}
                    />
                }

                {selectParent &&
                    <div className="panel">
                        <button className='btn_return' onClick={() => setSelectParent(false)}>Retour</button>
                        <button className='btn_submit'>Valider</button>
                    </div>
                }
            </form>

            {noParent &&
                <div className="mdl_msg_of_verif">
                    <div className="content">
                        <span>Parent non sélectionné</span>
                        <p>Vous n'avez pas choisi de parent à votre nouvelle catégorie. <br /><br />Êtes vous sûre ?</p>

                        <div className="panel">
                            <button type='button' onClick={() => setNoParent(false)} className='red'>non</button>
                            <button type='button' onClick={() => postCategory()}>oui</button>
                        </div>
                    </div>
                </div>
            }

            {alertMsg.length !== 0 && <AlertMessage alertMsg={alertMsg} setAlert={setAlert} />}
        </div>
    )
}

export default CreateCategories;