import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useFetchFile } from "../../hooks/useFetchFile";
import { useFetchImage } from "../../hooks/useFetchImage";
import { useFormatText } from "../../hooks/useFormatText";
import { useFormatFilename } from "../../hooks/useFormatFilename";

import PanelVerif from './../../components/PanelVerif';
import Slider from "../../components/Slider";

import "./style.css"

const langs = ['fr', 'en', 'sp', 'de'];

function VerifProducts() {
    const fetchFile = useFetchFile();
    const fetchImage = useFetchImage();
    const formatText = useFormatText();
    const formatFilename = useFormatFilename();

    const lang = useSelector(state => state.user.lang);
    const token = useSelector(state => state.user.token);
    const products_db = useSelector(state => state.data.products);

    const [products_list, setProductsList] = useState([]);
    const [dashboard, setDashboard] = useState(0);
    const [alertMsg, setAlert] = useState(null);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        if (!products_db || products_list.length !== 0) return
        main();

        async function main() {
            let new_productsList = products_db.filter(prod => prod.online === false);
            // Créez une copie des objets pour éviter de modifier les objets d'origine
            new_productsList = new_productsList.map(product => ({ ...product }));

            for (let i = 0; i < new_productsList.length; i++) {
                const product = new_productsList[i];

                let images = [];
                let associated = [];
                let files = {
                    fr: [],
                    en: [],
                    sp: [],
                    de: []
                };

                // Si il y a des images
                if (product.images.length !== 0) {
                    for (let j = 0; j < product.images.length; j++) {
                        const image = product.images[j];

                        const img_product = await fetchImage(image);
                        images.push(img_product);

                    }
                }
                new_productsList[i].images = images;

                // Si il y a des produits associés
                if (product.associated.length !== 0) {
                    for (let prodAss of product.associated) {
                        const fetchProd = products_db.find(prod => prod._id === prodAss);
                        let prodAssociated = { ...fetchProd, image: '' };

                        if (fetchProd.images.length !== 0) {
                            const img_product = await fetchImage(fetchProd.images[0]);
                            prodAssociated.image = img_product.image || '';
                        }
                        associated.push(prodAssociated);
                    }
                }
                new_productsList[i].associated = associated;

                // Si il y a des fichiers téléchargeable
                for (let lg of langs) {
                    let file_lang = [];

                    if (product.files[lg].length !== 0) {
                        for (let j = 0; j < product.files[lg].length; j++) {
                            const file = product.files[lg][j];
    
                            const file_product = await fetchFile(file);
                            file_lang.push(file_product.file || '');
                        }
                    }
                    files[lg] = file_lang;
                }
                new_productsList[i].files = files;
            }
            setProductsList(new_productsList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products_db])

    useEffect(() => {
        if (products_list.length === 0) return
        setDashboard(products_list[counter].references.length === 0 ? 1 : 0);
    }, [counter, products_list])

    const validation = async () => {
        const req = await fetch(`https://api.pro-vide.eu/admin/product/${products_list[counter]._id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ online: true })
        });
        const res = await req.json();

        // Code d'erreur
        const code = [404, 401];

        // Erreurs classiques
        if (code.includes(req.status)) setAlert({
            title: 'Une erreur c\'est produite lors de la mise en ligne',
            message: res.message
        });

        // Erreur serveur
        if (req.status === 500 || !res) setAlert({
            title: 'Erreur logique, contactez le développeur',
            message: res.message
        });

        if (req.status !== 200 || !res) return

        if (counter !== 0 && counter === products_list.length - 1) {
            setCounter(counter - 1);
        }

        const new_list = products_list.filter(serv => serv._id !== products_list[counter]._id);
        setProductsList(new_list);

        setAlert({
            title: 'Validé',
            message: 'Le produit à bien été mis en ligne'
        });
    }

    return (
        <div className='modal modal_verif modal_verif_products'>
            <h1>Validation des produits</h1>

            <PanelVerif counter={counter} setCounter={setCounter} list={products_list} validation={validation} />

            {products_list.length !== 0 && products_list[counter] &&
                <section className="sect_verif sect_verif_products">
                    <Slider
                        data={products_list[counter]}
                        className='products'
                        btn_type={products_list[counter].references.length === 0 ? 'request' : 'show_more'}
                        img_type='multi'
                    />

                    <button className='onglet_page'>
                        <div className='preview'>
                            <img src={products_list[counter].images[0] && products_list[counter].images[0].image} alt="error_image" />
                        </div>

                        <span>{products_list[counter][`name_${lang}`]}</span>
                    </button>

                    {(products_list[counter][`features_${lang}`].length !== 0 || products_list[counter][`benefits_${lang}`].length !== 0) &&
                        <div className="features_and_benefits">
                            <div className="container">
                                {products_list[counter][`features_${lang}`].length !== 0 &&
                                    <ul className="features">
                                        <li><h4>Caracteristiques</h4></li>

                                        {products_list[counter][`features_${lang}`].map((_, idx) => (
                                            <li key={idx}><p>{_}</p></li>
                                        ))}
                                    </ul>
                                }

                                {products_list[counter][`benefits_${lang}`].length !== 0 &&
                                    <ul className="benefits">
                                        <li><h4>Avantages</h4></li>

                                        {products_list[counter][`benefits_${lang}`].map((_, idx) => (
                                            <li key={idx}><p>{_}</p></li>
                                        ))}
                                    </ul>
                                }
                            </div>
                        </div>
                    }


                    <div className="dashboard">
                        <div className="onglets">
                            {products_list[counter].references.length !== 0 &&
                                <button className={dashboard === 0 ? 'actif' : ''} onClick={() => setDashboard(0)}>
                                    Références
                                </button>
                            }

                            <button className={dashboard === 1 ? 'actif' : ''} onClick={() => setDashboard(1)}>
                                Description
                            </button>

                            <button className={dashboard === 2 ? 'actif' : ''} onClick={() => setDashboard(2)}>
                                Téléchargements
                            </button>

                            <button className={dashboard === 3 ? 'actif' : ''} onClick={() => setDashboard(3)}>
                                Produits associés
                            </button>
                        </div>

                        {/* {dashboard === 0 && products_list[counter].references.length !== 0 &&
                            
                        } */}

                        {dashboard === 1 &&
                            <section className='sect_descrip'>
                                <div className="container_descrip">
                                    <h4>Description</h4>

                                    <p className='descrip'>
                                        {formatText(products_list[counter][`text_${lang}`])}
                                    </p>

                                    {!products_list[counter][`text_${lang}`] && <p className="no-data">Aucun description</p>}
                                </div>
                            </section>
                        }

                        {dashboard === 2 &&
                            <section className='sect_download'>
                                <div className="container_descrip">
                                    <h4>Fichiers téléchargeable</h4>

                                    <ul className='download_list'>
                                        {products_list[counter].files[lang].map((file, idx) =>
                                            <li key={idx}>
                                                {file && <button>- {formatFilename(file)}</button>}
                                            </li>
                                        )}
                                        {products_list[counter].files[lang].length === 0 && <li className='no-data'>Aucun fichier à télécharger</li>}
                                    </ul>
                                </div>
                            </section>
                        }

                        {dashboard === 3 &&
                            <ul className='prod_associated_list'>
                                {products_list[counter].associated.length !== 0 && products_list[counter].associated.map((prod, idx) => (
                                    <li key={idx}>
                                        <button>
                                            <div className='preview'>
                                                <img src={prod.image} alt='error_image' />
                                            </div>

                                            <span>{prod[`name_${lang}`]}</span>
                                        </button>

                                    </li>
                                ))}

                                {products_list[counter].associated.length === 0 && <li className='no-data'>Aucun produit associé</li>}
                            </ul>
                        }
                    </div>
                </section>
            }

            {alertMsg &&
                <div className="mdl_msg_of_verif">
                    <div className="content">
                        <span>{alertMsg.title}</span>
                        <p>{alertMsg.message}</p>

                        <div className="panel">
                            <button type='button' onClick={() => setAlert(null)}>Compris</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default VerifProducts;