import { useSelector } from "react-redux";

import ModalSelector from "../../selectors/Modal";

import ModalAchievments from "../../modal/ModalAchievments";
import CreateAchievments from "../../modalCreate/CreateAchievments";
import UpdateAchievments from "../../modalUpdate/UpdateAchievments";
import DeleteAchievments from "../../modalDelete/DeleteAchievments";
import VerifAchievments from "../../modalVerif/VerifAchievments";

import "./style.css"

function Achievments() {
    const process = useSelector(state => state.process);

    return (
        <div className='page page_achievments'>
            <ModalSelector page='réalisation' un='une' />

            {process.modal === 'list' && <ModalAchievments />}
            {process.modal === 'create' && <CreateAchievments />}
            {process.modal === 'update' && <UpdateAchievments />}
            {process.modal === 'delete' && <DeleteAchievments />}
            {process.modal === 'verif' && <VerifAchievments />}
        </div>
    )
}

export default Achievments;