import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useFormatText } from "../../hooks/useFormatText";

import SliderImg from "../SliderImg";

import "./style.css"

function Slider({ data, className, btn_type, img_type }) {
    const formatText = useFormatText();

    const lang = useSelector(state => state.user.lang);

    const [counter, setCounter] = useState(0);
    useEffect(() => setCounter(0), [data]);

    const prev = () => {
        setCounter(counter === 0 ? parseInt(data.images.length) - 1 : counter - 1);
    }

    const next = () => {
        setCounter(counter === parseInt(data.images.length) - 1 ? 0 : counter + 1);        
    }

    return className !== 'services' ? (
        <div className={`slider slider_${className}`}>
            <div className="content">
                <div className="descrip">
                    <h2>{data[`name_${lang}`]}</h2>

                    {data[`text_${lang}`] !== '' && <p>{formatText(data[`text_${lang}`])}</p>}

                    {btn_type === 'show_more' && <button className='btn_descr'>Voir plus</button>}
                    {btn_type === 'request' && <button className='btn_descr'>Faire une demande</button>}
                </div>

                {img_type === 'simple' &&
                    <div className="img_category">
                        <img src={data.image} alt="error_image" />
                    </div>
                }

                {img_type === 'multi' &&
                    <SliderImg images={data.images} />
                }
            </div>

            <div className="dots">
                <i className='dot dot_actif'></i>
                <i className='dot'></i>
                <i className='dot'></i>
                <i className='dot'></i>
                <i className='dot'></i>
                <i className='dot'></i>
                <i className='dot'></i>
                <i className='dot'></i>
            </div>
        </div>
    ) : (
        <div className='slider_services'>
            {data.images.length > 1 && <button onClick={() => prev()} className='arrow arrow_left'>{'<'}</button>}
            <div className="border_view">
                <div className="view">
                    <img src={data.images[counter] && data.images[counter].image} alt='Illustration' />
                </div>
            </div>
            {data.images.length > 1 && <button onClick={() => next()} className='arrow arrow_right'>{'>'}</button>}

            {data.images.length !== 1 &&
                <div className="dots">
                    {data.images.map((e, idx) =>
                        <i key={idx} className={idx === counter ? 'dot dot_actif' : 'dot'}></i>
                    )}
                </div>
            }
        </div>
    )
}

export default Slider;