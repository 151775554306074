import { useSelector } from "react-redux";

import ModalSelector from "../../selectors/Modal";

import ModalReferences from "../../modal/ModalReferences";
import CreateReferences from "../../modalCreate/CreateReferences";
import UpdateReferences from "../../modalUpdate/UpdateReferences";
import DeleteReferences from "../../modalDelete/DeleteReferences";

import "./style.css"

function References() {
    const process = useSelector(state => state.process);
    
    return (
        <div className='page page_references'>
            <ModalSelector page='référence' un='une' />

            {process.modal === 'list' && <ModalReferences />}
            {process.modal === 'create' && <CreateReferences />}
            {process.modal === 'update' && <UpdateReferences />}
            {process.modal === 'delete' && <DeleteReferences />}
        </div>
    )
}

export default References;