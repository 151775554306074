import { useSelector } from "react-redux";

import ModalSelector from "../../selectors/Modal";

import ModalServices from "../../modal/ModalServices";
import CreateServices from "../../modalCreate/CreateServices";
import UpdateServices from "../../modalUpdate/UpdateServices";
import DeleteServices from "../../modalDelete/DeleteServices";
import VerifServices from "../../modalVerif/VerifServices";

import "./style.css"

function Services() {
    const process = useSelector(state => state.process);
    
    return (
        <div className='page page_services'>
            <ModalSelector page='service' un='un' />

            {process.modal === 'list' && <ModalServices />}
            {process.modal === 'create' && <CreateServices />}
            {process.modal === 'update' && <UpdateServices />}
            {process.modal === 'delete' && <DeleteServices />}
            {process.modal === 'verif' && <VerifServices />}
        </div>
    )
}

export default Services;