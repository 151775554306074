import { useSelector } from "react-redux";

import InputList from "../InputList";
import InputFileList from "../InputFileList";

import "./style.css"

function BenefitsAndFeatures({ formData, setFormData }) {
    const lang = useSelector(state => state.user.lang);

    const addRow = (array) => {
        setFormData({
            ...formData,
            [array]: [...formData[array], '']
        })
    }

    const delRow = (array) => {
        setFormData({
            ...formData,
            [array]: [...formData[array].slice(0, -1)]
        })
    }

    return (
        <section className="benefits_features_files">
            <div className="list benefits">
                <h2>Avantages :</h2>

                <ul>
                    {formData[`benefits_${lang}`].map((e, idx) =>
                        <InputList key={idx} placeholder='Avantage du produit' formData={formData} setFormData={setFormData} idx={idx} field={`benefits_${lang}`} />
                    )}
                </ul>

                <div className="panel_list">
                    <button
                        type='button'
                        className='add'
                        onClick={() => addRow(`benefits_${lang}`)}
                    >Ajouter</button>

                    <button
                        type='button'
                        className='del'
                        onClick={() => delRow(`benefits_${lang}`)}
                    >Retirer</button>
                </div>
            </div>

            <div className="list features">
                <h2>Caractéristiques :</h2>

                <ul>
                    {formData[`features_${lang}`].map((e, idx) =>
                        <InputList key={idx} placeholder='Caractéristique du produit' formData={formData} setFormData={setFormData} idx={idx} field={`features_${lang}`} />
                    )}
                </ul>

                <div className="panel_list">
                    <button
                        type='button'
                        className='add'
                        onClick={() => addRow(`features_${lang}`)}
                    >Ajouter</button>

                    <button
                        type='button'
                        className='del'
                        onClick={() => delRow(`features_${lang}`)}
                    >Retirer</button>
                </div>
            </div>
            
            <InputFileList formData={formData} setFormData={setFormData} />
        </section>
    )
}

export default BenefitsAndFeatures;