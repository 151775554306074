import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../redux";

export const useSetOrder = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);

    const setOrder = async (route, _id, id, setAlert) => {
        dispatch(setLoader(1));

        const req = await fetch(`https://api.pro-vide.eu/admin/${route}-order/${_id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ changeFor: id })
        });
        const res = await req.json();
        
        if ([404, 401, 400].includes(req.status)) setAlert(prev => [...prev, {
            title: 'Erreur',
            message: res.message
        }]);
        if (req.status === 500 || !res) setAlert(prev => [...prev, {
            title: 'Erreur contactez le développeur',
            message: res.message
        }]);

        dispatch(setLoader(-1));
        return res || false
    };
    return setOrder;
};
