import { useEffect, useState } from "react";

import "./style.css"

function SliderImg({ images }) {
    const [counter, setCounter] = useState(0);
    useEffect(() => setCounter(0), [images]);
    
    return images && images.length !== 0 && (
        <div className="slider_img">
            <div className="view">
                {images[counter] && <img src={images[counter].image} alt="error_img" />}
            </div>

            <div className="panel_slider">
                {images.map((img, idx) =>
                    <button 
                        key={idx}
                        onClick={() => setCounter(idx)}
                        className={idx === counter ? 'actif' : ''}
                    >
                        <img src={img.image} alt="error_img" />
                    </button>
                )}
            </div>
        </div>
    )
}

export default SliderImg;