import "./style.css"

function PanelVerif({ counter, setCounter, list, validation }) {

    return (
        <div className="panel_verif">
            <div className="next_or_previous">
                {counter > 0 && <button onClick={() => setCounter(counter - 1)}>Précédent</button>}
                {counter < list.length - 1 && <button onClick={() => setCounter(counter + 1)} className='red'>Suivant</button>}
            </div>

            {list.length !== 0 && <button onClick={() => validation()}>Valider</button>}
        </div>
    )
}

export default PanelVerif;