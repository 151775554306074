import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAchievmentsToState, setLoader } from '../../redux';

import { usePostImage } from '../../hooks/usePostImage';
import { useDeleteImage } from '../../hooks/useDeleteImage';

import Lang from './../../selectors/Lang';
import NameAndText from '../../components/NameAndText';
import InputImages from '../../components/InputImages';
import AchievmentSelector from '../../selectors/Achievment';
import AlertMessage from '../../components/AlertMessage';

import "./style.css"

const init_form = {

    name_fr: '',
    name_en: '',
    name_sp: '',
    name_de: '',

    text_fr: '',
    text_en: '',
    text_sp: '',
    text_de: '',

    images: Array(9).fill({ value: '', prev: null }),

    parentId: '',
}


function UpdateAchievments() {
    const dispatch = useDispatch();

    const postImage = usePostImage();
    const deleteImage = useDeleteImage();

    const token = useSelector(state => state.user.token);
    const achievments_db = useSelector(state => state.data.achievments);

    const [formData, setFormData] = useState(init_form);
    const [lastData, setLastData] = useState(null);
    const [alertMsg, setAlert] = useState([]);

    const submitForm = async (event) => {
        event.preventDefault();

        if (!formData) return

        if (formData.name_fr === '') {
            setAlert([...alertMsg, {
                title: 'Champ manquant',
                message: 'Le champ "Nom de la réalisation" est requis au bon fonctionnement de votre site'
            }]);
            return
        }
        await updateAchievments();
    }

    const updateAchievments = async () => {
        dispatch(setLoader(1));

        let body = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,
        }

        const images = await updateImages();
        body.images = images;

        const req = await fetch(`https://api.pro-vide.eu/admin/achievement/${formData._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        dispatch(setLoader(-1));

        if ([404, 401, 400].includes(req.status)) setAlert([...alertMsg, {
            title: 'Une erreur c\'est produite lors de la modification de la réalisation',
            message: res.message
        }]);

        if (req.status === 500) setAlert([...alertMsg, {
            title: 'Erreur logique, contactez le développeur',
            message: res.message
        }]);

        let new_achievments_db = [];

        // Mise à jour de la state 'achievments'
        for (let achievment of achievments_db) {
            if (achievment._id !== formData._id) {
                new_achievments_db.push(achievment);
                continue
            };

            new_achievments_db.push({
                ...body,
                _id: formData._id,
                name: body.name_fr,
                parentId: formData.parentId
            })
        }

        dispatch(addAchievmentsToState(new_achievments_db));

        setFormData({ ...init_form });
        setLastData(null);
        
        setAlert([...alertMsg, {
            title: 'Réalisation modifié',
            message: 'Votre réalisation a bien été modifié'
        }]);
    }

    const updateImages = async () => {
        let response = [];

        for (let i = 0; i < formData.images.length; i++) {
            const image_last = lastData.images[i] || null;
            const image = formData.images[i];

            // Si l'image reste inchangé
            if (image._id) {
                response.push(image._id);
                continue
            }

            // Si il n'y a pas et n'avait pas d'image
            if (!image_last._id && !image._id && image.value === '') continue

            // Si l'ancienne image a été supprimé
            if (image_last._id && !image._id) {
                const image_delete = await deleteImage(image_last._id, setAlert);

                if (!image_delete) {
                    setAlert([...alertMsg, {
                        title: 'Problème d\'image',
                        message: 'Un problème est survenue lors de la suppression de la précédente image'
                    }]);
                    response.push(image_last._id);
                    continue
                }
                if (image.value === '') continue
            }

            // Si une nouvelle image est ajouté
            if (image.value !== '') {
                const formImage = new FormData();
                formImage.append('image', formData.images[i].value);

                // Ajout de l'image à la db
                const image_id = await postImage(setAlert, formImage);

                // Si l'image n'est pas ajouté on continue
                if (!image_id) {
                    setAlert([...alertMsg, {
                        title: 'Problème d\'image',
                        message: `Un problème est survenue lors de la ajout de l'image n° ${i}`
                    }]);
                    continue
                }
                response.push(image_id)
            }
        }
        return response
    }

    const reload = () => {
        setFormData(init_form);
        setLastData(null);
    }

    return (
        <div className='modal modal_update_achievments'>
            {lastData && <Lang />}

            <form onSubmit={submitForm}>
                <h1>Modifier une réalisation</h1>

                {!lastData &&
                    <AchievmentSelector
                        formData={formData}
                        setFormData={setFormData}
                        setLastData={setLastData}
                    />
                }

                {lastData &&
                    <section className='sect_name_text_images'>
                        <NameAndText formData={formData} setFormData={setFormData} placeholder='de la réalisation' />
                        <InputImages formData={formData} setFormData={setFormData} placeholder='de la réalisation' />
                    </section>
                }

                {lastData &&
                    <div className="panel">
                        <button className='btn_return' onClick={() => reload()}>Retour</button>
                        <button className='btn_update'>Modifier</button>
                    </div>
                }
            </form>

            {alertMsg.length !== 0 && <AlertMessage alertMsg={alertMsg} setAlert={setAlert} />}
        </div>
    )
}

export default UpdateAchievments;