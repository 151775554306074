import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { usePostLogin } from "../../hooks/usePostLogin";

import "./style.css"

import LottieLogin from "../../lotties/LootieLogin";
import LottieLoginMoon from "../../lotties/LootieLoginMoon";

import space from './../../assets/img/space.jpg';
import background from './../../assets/img/background_webp.webp';

function Login() {
    const navigate = useNavigate();
    const postLogin = usePostLogin();

    const [msgError, setMsgError] = useState('');
    const [formLogin, setFormLogin] = useState({
        admin_id: '',
        admin_pw: ''
    });

    const setInput = (e, field) => {
        let newValue = e.target.value.replace(/[<>{}]/g, '');
        setFormLogin(prev => ({ ...prev, [field]: newValue }));
        return
    }

    const submitForm = async (event) => {
        event.preventDefault();

        const isLogin = await postLogin(formLogin.admin_id, formLogin.admin_pw, setMsgError);

        if (isLogin) navigate('/');
    }


    return formLogin && (
        <div className='page_login'>
            <img className="background_site" src={background} alt=" " />

            <section>
                <div className='ctn_presentation'>
                    <span>mySpace</span>
                    <p>Accedez à votre interface mySpace pour pouvoir veiller et éditer votre site.</p>

                    <LottieLogin />
                    <LottieLoginMoon />
                    <img className='space' src={space} alt=" " />
                </div>

                <form onSubmit={submitForm}>
                    <span>Connexion</span>

                    <div className="grid">
                        <div className="input_login">
                            <label>Identifiant</label>

                            <input
                                type="text"
                                placeholder='MySpace'

                                value={formLogin.admin_id}
                                onChange={e => setInput(e, 'admin_id')}
                            />
                        </div>

                        <div className="input_login">
                            <label>Mot de passe :</label>

                            <input
                                type="text"
                                placeholder='************'

                                value={formLogin.admin_pw}
                                onChange={e => setInput(e, 'admin_pw')}
                            />
                        </div>
                    </div>

                    {msgError !== '' && <p className='msg_error'>{msgError}</p>}

                    <button type="submit" className='btn_submit'>Confirmer</button>
                </form>
            </section>
        </div>
    )
}

export default Login;