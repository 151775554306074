import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addServicesToState, setLoader } from '../../redux';

import { usePostFile } from '../../hooks/usePostFile';
import { usePostImage } from '../../hooks/usePostImage';
import { usePageIsEmpty } from '../../hooks/usePageIsEmpty';

import Lang from './../../selectors/Lang';
import NameAndText from '../../components/NameAndText';
import InputImages from '../../components/InputImages';
import ParentSelector from '../../selectors/Parent';
import AlertMessage from '../../components/AlertMessage';

import "./style.css"
import InputFileList from '../../components/InputFileList';

const langs = ['fr', 'en', 'sp', 'de'];

const init_form = {

    name_fr: '',
    name_en: '',
    name_sp: '',
    name_de: '',

    text_fr: '',
    text_en: '',
    text_sp: '',
    text_de: '',

    images: Array(9).fill({ value: '', prev: null }),
    video: '',

    files: {
        fr: [],
        en: [],
        sp: [],
        de: [],
    },

    parentId: '',
}


function CreateServices() {
    const dispatch = useDispatch();
    const pageIsEmpty = usePageIsEmpty();

    const postFile = usePostFile();
    const postImage = usePostImage();

    const token = useSelector(state => state.user.token);

    const process = useSelector(state => state.process);
    const services_db = useSelector(state => state.data.services);
    const categories_db = useSelector(state => state.data.categories);

    const [selectParent, setSelectParent] = useState(false);
    const [formData, setFormData] = useState(init_form);
    const [alertMsg, setAlert] = useState([]);

    useEffect(() => {
        if (!formData) return
        if (formData && formData.name_fr !== '' && !process.pageIsEmpty) return

        pageIsEmpty(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const submitForm = async (event) => {
        event.preventDefault();

        if (!formData) return

        if (formData.name_fr === '') {
            setAlert([...alertMsg, {
                title: 'Champ manquant',
                message: 'Le champ "Nom du service" est requis au bon fonctionnement de votre site'
            }]);
            return
        }

        if (formData.parentId === '') {
            setAlert([...alertMsg, {
                title: 'Catégorie manquante',
                message: 'Vous n\'avez pas renseigné la "Catégorie parente" celle-ci est requis au bon fonctionnement de votre site'
            }]);
            return
        }

        await postService();
    }

    const postService = async () => {
        const category_exist = categories_db.filter(prod => prod.parentId === formData.parentId);
        if (category_exist.length !== 0) {
            setAlert([...alertMsg, {
                title: 'Catégorie parente invalide',
                message: `La catégorie parente selectionné contient déja une ou plusieurs catégorie(s)`
            }]);
            return
        }
        dispatch(setLoader(1));

        let body = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,

            video: formData.video,
            images: [],

            parentId: formData.parentId,
        }

        body.images = await postImages();
        body.files = await postFiles();

        const req = await fetch(`https://api.pro-vide.eu/admin/service`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        dispatch(setLoader(-1));

        if ([404, 401, 400].includes(req.status)) {
            setAlert([...alertMsg, {
                title: 'Une erreur c\'est produite',
                message: res.message
            }]);
            return
        }
        if (req.status !== 201 || !res) {
            setAlert([...alertMsg, {
                title: 'Serveur indisponible',
                message: res.message
            }]);
            return
        }

        const new_services_db = [...services_db, { ...res, name: res.name_fr }];
        dispatch(addServicesToState(new_services_db));

        setFormData({ ...init_form, parentId: formData.parentId });
        setSelectParent(false);
        setAlert([...alertMsg, {
            title: 'Service ajouté',
            message: 'Votre service a bien été ajouté à la base de donnée'
        }]);
    }

    const postImages = async () => {
        let response = [];

        for (let img of formData.images) {
            if (img.value === '') continue

            const formImage = new FormData();
            formImage.append('image', img.value);

            const image = await postImage(setAlert, formImage);
            if (!image) continue
            response.push(image);
        }

        return response
    }

    const postFiles = async () => {
        let response = {
            fr: [],
            en: [],
            sp: [],
            de: []
        };

        for (let lang of langs) {
            for (let file of formData.files[lang]) {
                const formFile = new FormData();
                formFile.append('file', file);

                const file_id = await postFile(setAlert, formFile);
                if (!file_id) continue
                response[lang].push(file_id);
            }
        }
        return response
    }

    return (
        <div className='modal modal_create_services'>
            {!selectParent && <Lang />}

            <form onSubmit={submitForm}>
                {!selectParent &&
                    <>
                        <h1>Ajouter un service</h1>

                        <section className='sect_name_text_images'>
                            <NameAndText formData={formData} setFormData={setFormData} placeholder='du service' />
                            <InputImages formData={formData} setFormData={setFormData} placeholder='du service' />
                        </section>

                        <div className="flyers_video">
                            <InputFileList formData={formData} setFormData={setFormData} />

                            <div className="input_video">
                                <h2>Lien vidéo :</h2>

                                <input
                                    type="text"
                                    className='inp_video'
                                    placeholder='https://www.youtube.com/watch?v=GkZOF9Wk3Zc&t=50s'

                                    value={formData.video}
                                    onChange={(e) => setFormData({ ...formData, video: e.target.value })}
                                />
                            </div>
                        </div>

                        {!process.pageIsEmpty &&
                            <button
                                type='button'
                                className='btn_next'
                                onClick={() => setSelectParent(true)}
                            >Suivant</button>
                        }
                    </>
                }

                {selectParent &&
                    <ParentSelector
                        formData={formData}
                        setFormData={setFormData}
                        options={{
                            placeholder: 'du service',
                            type: 'serv'
                        }}
                    />
                }

                {selectParent &&
                    <div className="panel">
                        <button className='btn_return' onClick={() => setSelectParent(false)}>Retour</button>
                        {formData.parentId !== '' && <button className='btn_submit'>Valider</button>}
                    </div>
                }
            </form>

            {alertMsg.length !== 0 && <AlertMessage alertMsg={alertMsg} setAlert={setAlert} />}
        </div>
    )
}

export default CreateServices;