import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./style.css"

function ParentSelector({ formData, setFormData, options, lock }) {
    const [path, setPath] = useState(null);
    const [data, setData] = useState([]);

    const categories_db = useSelector(state => state.data.categories);

    useEffect(() => {
        if (!options) return

        const new_data = categories_db.filter(cat => 
            (options.type === 'all' || cat.type === options.type) && 
            (path && !lock ? cat.parentId === path : !cat.parentId)
        );
        setData(new_data);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.type, path])

    const previous = () => {
        const previous_category = categories_db.find(cat => cat._id === path);
        setPath(previous_category.parentId || null);
    }

    const goPath = (category) => {
        const categories_children = categories_db.filter(cat => !lock && cat.parentId === category._id);
        if (categories_children.length !== 0) setPath(category._id);
    }

    const selectParent = (category) => {
        const new_form = {
            ...formData,
            parentId: formData.parentId !== category._id ? category._id : ''
        }

        setFormData(new_form);
    }

    return (
        <div className='category_selector'>
            <h2>Localisation {options.placeholder}</h2>

            {data.length !== 0 &&
                <ul className='list'>
                    {path &&
                        <li className='li_blue'>
                            <button
                                type='button'
                                className='path'
                                onClick={() => previous()}
                            >
                                Revenir en arrière
                            </button>
                        </li>
                    }

                    {data.map((category, idx) =>
                        <li key={idx} className={path ? 'li_yellow' : 'li_blue'}>
                            <button
                                type='button'
                                className='path'
                                onClick={() => goPath(category)}
                            >{category.name_fr}</button>

                            <button
                                type='button'
                                className='check_box'
                                onClick={() => selectParent(category)}
                            >
                                {formData.parentId === category._id && <i>✔</i>}
                            </button>
                        </li>
                    )}
                </ul>
            }

            {data.length === 0 &&
                <ul className='void_list'>
                    {path &&
                        <li className='li_blue'>
                            <button
                                type='button'
                                className='path'
                                onClick={() => setPath(null)}
                            >
                                Revenir en arrière
                            </button>
                        </li>
                    }
                    <li className='no_value'>Aucune données</li>
                </ul>
            }
        </div>
    )
}

export default ParentSelector;