import { configureStore, createSlice } from "@reduxjs/toolkit";


// ------------------------------------------------------------------------------------------------
// State process

const processSlice = createSlice({
    name: "process",
    initialState: {
        loader: 0,
        w_page: '',
        w_modal: '',
        modal: 'list',
        pageIsEmpty: true,
    },
    reducers: {
        setLoader: (state, action) => {
            state.loader = state.loader + action.payload;
        },
        setPage: (state, action) => {
            state.w_page = action.payload;
        },
        setPageIsEmpty: (state, action) => {
            state.pageIsEmpty = action.payload;
        },
        setModal: (state, action) => {
            if (action.payload === null) {
                state.w_modal = '';
                return
            }
            
            if (state.pageIsEmpty) state.w_modal = '';
            !state.pageIsEmpty ? state.w_modal = action.payload : state.modal = action.payload;
        }
    }
})

export const { setLoader, setModal, setPage, setPageIsEmpty } = processSlice.actions;


// ------------------------------------------------------------------------------------------------
// State utilisateur

const userSlice = createSlice({
    name: "user",
    initialState: {
        admin_id: '',
        admin_pw: '',
        lang: 'fr',
        token: '',
    },
    reducers: {
        login: (state, action) => {
            const { admin_id, admin_pw, token } = action.payload;
            state.admin_id = admin_id || '';
            state.admin_pw = admin_pw || '';
            state.token = token || '';
        },
        logout: (state, action) => {
            state.admin_id = '';
            state.admin_pw = '';
            state.token = '';
        },
        setLang: (state, action) => {            
            state.lang = action.payload;
        }
    }
})

export const { login, logout, setLang } = userSlice.actions;


// ------------------------------------------------------------------------------------------------
// State des données

const dataSlice = createSlice({
    name: "data",
    initialState: {
        categories: [],
        products: [],
        references: [],
        services: [],
        achievments: [],
    },
    reducers: {
        addCategoriesToState: (state, action) => {
            state.categories = action.payload;
        },
        addProductsToState: (state, action) => {
            state.products = action.payload;
        },
        addReferencesToState: (state, action) => {
            state.references = action.payload;
        },
        addServicesToState: (state, action) => {
            state.services = action.payload;
        },
        addAchievmentsToState: (state, action) => {
            state.achievments = action.payload;
        }
    }
})

export const { addCategoriesToState, addProductsToState, addReferencesToState, addServicesToState, addAchievmentsToState } = dataSlice.actions;

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export const store = configureStore({
    reducer: {
        process: processSlice.reducer,
        user: userSlice.reducer,
        data: dataSlice.reducer,
    }
});