import { useSelector } from "react-redux";
import { useFormatFilename } from '../../hooks/useFormatFilename';

import "./style.css"

import trash from './../../assets/svg/svg_trash_red.svg';

function InputFileList({ formData, setFormData }) {
    const formatFilename = useFormatFilename();
    const lang = useSelector(state => state.user.lang);

    const addFile = (e) => {
        const file = e.target.files[0];

        const arrayFiles = [
            ...formData.files[lang],
            file
        ];

        setFormData({
            ...formData,
            files: {
                ...formData.files,
                [lang]: arrayFiles
            }
        })
    }

    const delFile = (idx) => {
        const arrayFiles = formData.files[lang].filter((e, id) => id !== idx);

        setFormData({
            ...formData,
            files: {
                ...formData.files,
                [lang]: arrayFiles
            }
        })
    }

    const changePosUp = (idx) => {
        if (idx === 0) return
        let last_files = formData.files;

        const newList = [...last_files[lang]];
        [newList[idx], newList[idx - 1]] = [newList[idx - 1], newList[idx]];

        setFormData({
            ...formData,
            files: { ...last_files, [lang]: newList }
        });
    }

    const changePosDown = (idx) => {
        let last_files = formData.files;
        if (idx === last_files[lang].length - 1) return

        const newList = [...last_files[lang]];
        [newList[idx], newList[idx + 1]] = [newList[idx + 1], newList[idx]];

        setFormData({
            ...formData,
            files: { ...last_files, [lang]: newList }
        });
    }

    return (
        <div className="input_files">
            <h2>Fichiers téléchargeable :</h2>

            <div className="inp_file">
                <input
                    type="file"
                    onChange={(e) => addFile(e)}
                />
                <p>+ Ajouter un fichier <span>(.step, .pdf, dwg, .dxf, .igs)</span></p>
            </div>

            <ul className='files_list'>
                {formData.files[lang] && formData.files[lang].length !== 0 && formData.files[lang].map((file, idx) =>
                    <li key={idx}>
                        <p>{file._id ? formatFilename(file.name) : file.name}</p>

                        <div className="panel_input_file">
                            <button 
                                type='button'
                                className="btn_up-down_file"
                                onClick={() => changePosUp(idx)}
                            >
                                <i className="fas fa-chevron-up"></i>
                            </button>

                            <button
                                type='button'
                                className='btn_del_file'
                                onClick={() => delFile(idx)}
                            >
                                <img src={trash} alt="X" />
                            </button>

                            <button 
                                type='button'
                                className="btn_up-down_file"
                                onClick={() => changePosDown(idx)}
                            >
                                <i className="fas fa-chevron-down"></i>
                            </button>
                        </div>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default InputFileList;