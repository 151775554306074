import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addProductsToState } from "../../redux";

import { useSetOrder } from "../../hooks/useSetOrder";
import { useFetchImage } from '../../hooks/useFetchImage';

import AlertMessage from '../../components/AlertMessage';

import "./style.css"

import icon_return from '../../assets/img/icon_return.png'
import icon_return_color from '../../assets/img/icon_return_color.png'

function ModalProducts() {
    const dispatch = useDispatch();
    const setOrder = useSetOrder();
    const fetchImage = useFetchImage();

    const [categories_list, setCategories] = useState([]);
    const [products_list, setProducts] = useState([]);
    const [alertMsg, setAlert] = useState([]);
    const [path, setPath] = useState(null);

    const categories_db = useSelector(state => state.data.categories);
    const products_db = useSelector(state => state.data.products);

    useEffect(() => {
        if (!categories_db) return
        setCategories([]);
        setProducts([]);

        const fetchImages = async (datas, setList, type) => {
            let new_list = [];

            if (type === 'simple') {
                for (let data of datas) {
                    let new_data_list = { ...data }

                    if (data.image) {
                        const image = await fetchImage(data.image);
                        new_data_list.image = image;
                    }
                    new_list.push(new_data_list);
                }
            }

            if (type === 'multi') {
                for (let data of datas) {
                    let new_data_list = { ...data }

                    if (data.images.length !== 0) {
                        const image = await fetchImage(data.images[0]);
                        new_data_list.image = image;
                    }
                    new_list.push(new_data_list);
                }
            }
            setList(new_list);
        }

        const categories = categories_db.filter(cat => cat.type === 'prod' && (path ? cat.parentId === path : !cat.parentId));

        if (categories.length !== 0) {
            fetchImages(categories, setCategories, 'simple');
            return
        }

        if (!path) return

        const products = products_db.filter(prod => prod.parentId === path);
        if (products.length !== 0) {
            fetchImages(products, setProducts, 'multi');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories_db, products_db, path])

    const previous = () => {
        const parent = categories_db.find(cat => cat._id === path);
        setPath(parent.parentId);
    }

    const goTo = (_id) => {
        const childrens = categories_db.filter(cat => cat.type === 'prod' && cat.parentId === _id);
        const childrensProd = products_db.filter(prod => prod.parentId === _id);
        if (childrens.length !== 0 || childrensProd.length !== 0) setPath(_id);
    }

    const changePosPrev = async (product) => {
        if (product.order === 0) return;
    
        const prev_element = products_list.find(prod => prod.order === product.order - 1);
    
        if (!prev_element) {
            setAlert([...alertMsg, {
                title: `Erreur`,
                message: 'Contactez le développeur'
            }]);
            return;
        }
    
        const response = await setOrder('product', product._id, prev_element._id, setAlert);
        if (response !== true) return;
    
        const updatedProducts_db = products_db.map(prod => {
            if (prod._id === product._id) {
                return { ...prod, order: prod.order - 1 };
            } 
            else if (prod._id === prev_element._id) {
                return { ...prod, order: prod.order + 1 };
            }
            return prod;
        });
        dispatch(addProductsToState(updatedProducts_db.sort((a, b) => a.order - b.order)));
    
        const updatedProducts_list = products_list.map(prod => {
            if (prod._id === product._id) {
                return { ...prod, order: prod.order - 1 };
            } 
            else if (prod._id === prev_element._id) {
                return { ...prod, order: prod.order + 1 };
            }
            return prod;
        });
        setProducts(updatedProducts_list.sort((a, b) => a.order - b.order));
    }

    const changePosNext = async (product) => {
        if (product.order === products_list.length - 1) return;
    
        const next_element = products_list.find(prod => prod.order === product.order + 1);
    
        if (!next_element) {
            setAlert([...alertMsg, {
                title: `Erreur`,
                message: 'Contactez le développeur'
            }]);
            return;
        }
    
        const response = await setOrder('product', product._id, next_element._id, setAlert);
        if (response !== true) return;
    
        const updatedProducts_db = products_db.map(prod => {
            if (prod._id === product._id) {
                return { ...prod, order: prod.order + 1 };
            } 
            else if (prod._id === next_element._id) {
                return { ...prod, order: prod.order - 1 };
            }
            return prod;
        });
        dispatch(addProductsToState(updatedProducts_db.sort((a, b) => a.order - b.order)));
    
        const updatedProducts_list = products_list.map(prod => {
            if (prod._id === product._id) {
                return { ...prod, order: prod.order + 1 };
            } 
            else if (prod._id === next_element._id) {
                return { ...prod, order: prod.order - 1 };
            }
            return prod;
        });
        setProducts(updatedProducts_list.sort((a, b) => a.order - b.order));
    }

    return (
        <div className='modal modal_show modal_products'>
            <h1>Mes produits</h1>

            {categories_list.length !== 0 &&
                <ul className="categories_list_order">
                    {path &&
                        <li className="onglet_return_category">
                            <button
                                className='onglet_categories'
                                onClick={() => previous()}
                            >
                                <div className='preview'>
                                    <img
                                        src={icon_return}
                                        alt='Illustration'
                                    />
                                </div>

                                <span>Retour en arrière</span>
                            </button>
                        </li>
                    }

                    {categories_list.map((cat, idx) =>
                        <li className="ctn_onglet_category" key={idx}>
                            <button
                                className='onglet_categories'
                                onClick={() => goTo(cat._id)}
                            >
                                <div className='preview'>
                                    <img
                                        src={cat && cat.image && cat.image.image}
                                        alt='Illustration'
                                    />
                                </div>

                                <span>{cat.name_fr}</span>
                            </button>
                        </li>
                    )}
                </ul>
            }

            {products_list.length !== 0 &&
                <ul className="products_list_order">
                    {path &&
                        <li className="onglet_return_product">
                            <button
                                className='onglet_products'
                                onClick={() => previous()}
                            >
                                <div className='preview'>
                                    <img
                                        src={icon_return_color}
                                        alt='Illustration'
                                    />
                                </div>

                                <span>Retour en arrière</span>
                            </button>
                        </li>
                    }

                    {products_list.map((prod, idx) =>
                        <li className="ctn_onglet_product" key={idx}>
                            <button
                                className="btn_prev"
                                onClick={() => changePosPrev(prod)}
                            >
                                <i className="fas fa-chevron-left"></i>
                            </button>

                            <button className='onglet_products'>
                                <div className='preview'>
                                    <img
                                        src={prod && prod.image && prod.image.image}
                                        alt='Illustration'
                                    />
                                </div>

                                <span>{prod.name_fr}</span>
                            </button>

                            <button
                                className="btn_next"
                                onClick={() => changePosNext(prod)}
                            >
                                <i className="fas fa-chevron-right"></i>
                            </button>
                        </li>
                    )}
                </ul>
            }

            {alertMsg.length !== 0 && <AlertMessage alertMsg={alertMsg} setAlert={setAlert} />}
        </div>
    )
}

export default ModalProducts;