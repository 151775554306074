import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProductsToState, setLoader } from '../../redux';

import ProductSelector from '../../selectors/Product';
import AlertMessage from '../../components/AlertMessage';
import ConfirmDelete from '../../components/ConfirmDelete';

import "./style.css";

function DeleteProducts() {
    const dispatch = useDispatch();

    const [alertMsg, setAlert] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [toDelete, setToDelete] = useState(null);

    const token = useSelector(state => state.user.token);
    const products_db = useSelector(state => state.data.products);

    useEffect(() => {
        if (!toDelete || !confirm) return
        dispatch(setLoader(1));
        deleteProduct();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDelete, confirm])

    const deleteProduct = async () => {
        const req = await fetch(`https://api.pro-vide.eu/admin/product/${toDelete._id}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const res = await req.json();

        dispatch(setLoader(-1));

        if ([400, 401, 404].includes(req.status)) {
            setToDelete(null);
            setConfirm(false);

            setAlert([...alertMsg, {
                title: 'Une erreur s\'est produite',
                message: res.message
            }]);
            return;
        }

        if (req.status !== 200 || !res) {
            setToDelete(null);
            setConfirm(false);

            setAlert([...alertMsg, {
                title: 'Serveur indisponible',
                message: res.message
            }]);
            return;
        }

        // Mise à jour du state 'products'
        const new_products_db = products_db.filter(prod => prod._id !== toDelete._id);
        dispatch(addProductsToState(new_products_db));

        setAlert([...alertMsg, {
            title: 'Produit supprimé',
            message: `Le produit '${toDelete.name_fr}' a bien été supprimé.`
        }])

        setToDelete(null);
        setConfirm(false);
    };

    return (
        <div className='modal modal_delete_products'>
            <form onSubmit={(event) => event.preventDefault()}>
                <h1>Supprimer un produit</h1>
                
                <ProductSelector setToDelete={setToDelete} />
            </form>

            {toDelete && !confirm && <ConfirmDelete toDelete={toDelete} setToDelete={setToDelete} setConfirm={setConfirm} />}
            {alertMsg.length !== 0 && <AlertMessage alertMsg={alertMsg} setAlert={setAlert} />}
        </div>
    );
}

export default DeleteProducts;
