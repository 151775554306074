import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useFetchImage } from "../../hooks/useFetchImage";

import "./style.css"

import trash from './../../assets/svg/svg_trash_red.svg';

function CategorySelector({ formData, setFormData, setLastData, setToDelete, type }) {
    const fetchImage = useFetchImage();

    const [path, setPath] = useState(null);
    const [data, setData] = useState([]);

    const token = useSelector(state => state.user.token);
    const categories_db = useSelector(state => state.data.categories);

    useEffect(() => {
        if (!type || !categories_db) return

        const new_data = categories_db.filter(cat => cat.type === type && (path ? cat.parentId === path : !cat.parentId));
        setData(new_data);
        
    }, [categories_db, type, path])

    const previous = () => {
        const previous_category = categories_db.find(cat => cat._id === path);
        setPath(previous_category.parentId || null);
    }

    const goPath = (category) => {
        const categories_children = categories_db.filter(cat => cat.parentId === category._id);
        if (categories_children.length !== 0) setPath(category._id);
    }

    const selectCategory = async (category) => {
        if (!formData) {
            setToDelete(category);
            return
        }

        const req = await fetch(`https://api.pro-vide.eu/admin/category/${category._id}`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` }
        })
        const res = await req.json();

        if (req.status !== 200 || !res) {
            alert('Impossible de récupérer les données de la catégories');
            return
        }

        let image = {
            value: '',
            prev: null
        }

        if (res.image && res.image !== '') {
            const image_category = await fetchImage(res.image);

            image = {
                value: '',
                _id: image_category._id,
                prev: image_category.image
            }
        }

        const new_form = {
            _id: category._id,

            name_fr: res.name_fr || '',
            name_en: res.name_en || '',
            name_sp: res.name_sp || '',
            name_de: res.name_de || '',

            text_fr: res.text_fr || '',
            text_en: res.text_en || '',
            text_sp: res.text_sp || '',
            text_de: res.text_de || '',

            parentId: res.parentId || '',
            image: image
        }

        setFormData(new_form);
        setLastData(new_form);
    }

    return (
        <div className='category_selector'>
            {data.length !== 0 &&
                <ul className='list'>
                    {path &&
                        <li className='li_blue'>
                            <button
                                type='button'
                                className='path'
                                onClick={() => previous()}
                            >
                                Revenir en arrière
                            </button>
                        </li>
                    }

                    {data.map((category, idx) =>
                        <li key={idx} className={path ? 'li_yellow' : 'li_blue'}>
                            <button
                                type='button'
                                className='path'
                                onClick={() => goPath(category)}
                            >{category.name_fr}</button>

                            {formData &&
                                <button
                                    type='button'
                                    className='check_box'
                                    onClick={() => selectCategory(category)}
                                >
                                    {formData.parentId === category._id && <i>✔</i>}
                                </button>
                            }

                            {!formData &&
                                <button
                                    type='button'
                                    className='btn_delete'
                                    onClick={() => selectCategory(category)}
                                >
                                    <img src={trash} alt="X" />
                                </button>
                            }
                        </li>
                    )}
                </ul>
            }

            {data.length === 0 &&
                <ul className='void_list'>
                    {path &&
                        <li className='li_blue'>
                            <button
                                type='button'
                                className='path'
                                onClick={() => setPath(null)}
                            >
                                Revenir en arrière
                            </button>
                        </li>
                    }
                    <li className='no_value'>Aucune données</li>
                </ul>
            }
        </div>
    )
}

export default CategorySelector;