import { useState } from 'react';
import { addProductsToState, setLoader } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';

import { useFormatFilename } from '../../hooks/useFormatFilename';

import SearchBar from '../../components/SearchBar';
import AlertMessage from '../../components/AlertMessage';

import "./style.css";

import trash from './../../assets/svg/svg_trash_red.svg';

const init_form = {
    head: ['References', '3D-plan'],
    references: Array.from({ length: 0 }, () => ({ References: '', '3D-plan': '' }))
};


function DeleteReferences() {
    const dispatch = useDispatch();
    const formatFilename = useFormatFilename();

    const token = useSelector(state => state.user.token);
    const products_db = useSelector(state => state.data.products);

    const [search, setSearch] = useState({ id: '', value: '' });
    const [formData, setFormData] = useState(init_form);
    const [alertMsg, setAlert] = useState([]);

    const deleteReference = async (idx) => {
        dispatch(setLoader(1));

        const req = await fetch(`https://api.pro-vide.eu/admin/reference/${formData.references[idx]._id}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const res = await req.json();

        // Gestion des erreurs
        if ([400, 401, 404].includes(req.status)) {
            setAlert([ ...alertMsg, {
                title: 'Erreur lors de la suppression',
                message: res.message
            }]);
            return;
        }
        if (req.status === 500 || !res) {
            setAlert([ ...alertMsg, {
                title: 'Erreur logique, contactez le développeur',
                message: res.message
            }]);
            return;
        }
        if (req.status !== 200) return;

        const product = products_db.find(prod => prod._id === search.id);
        const product_updated = JSON.parse(JSON.stringify(product));

        const newReferences = formData.references.filter((_, i) => i !== idx);
        product_updated.references.data = newReferences;

        const new_products_db = products_db.filter(prod => prod._id !== search.id);
        
        dispatch(addProductsToState([...new_products_db, product_updated]));
        dispatch(setLoader(-1));

        if (newReferences.length === 0) {
            setSearch({ id: '', value: '' });
            setFormData(init_form);
        } 
        else setFormData({ ...formData, references: newReferences });

        setAlert([ ...alertMsg, {
            title: 'Référence supprimée',
            message: 'La référence a bien été supprimée.'
        }]);
    };

    return (
        <div className='modal modal_references modal_delete_references'>
            <h1>Supprimer des références</h1>

            <SearchBar search={search} setSearch={setSearch} setFormData={setFormData} type='delete' />

            <div className="table_ref">
                <ul>
                    {formData.head.map((head, idx) => (
                        <li key={idx}>
                            <p className='input_table'>{head}</p>
                        </li>
                    ))}
                </ul>

                {formData.references.map((ref, idx) => (
                    <ul key={idx}>
                        {formData.head.map((head, id) => (
                            <li key={id}>
                                {head !== '3D-plan' && <p className='input_table'>{ref[head]}</p>}

                                {head === '3D-plan' &&
                                    <div className="input_schema">
                                        {ref[head].image && <label>{formatFilename(ref[head]?.image)}</label>}
                                        {ref[head].plan && <label>{formatFilename(ref[head]?.plan)}</label>}
                                        {ref[head].file && <label>{formatFilename(ref[head]?.file)}</label>}
                                        {!ref[head] && <label>Aucun schema</label>}
                                    </div>
                                }

                                {head === 'References' && (
                                    <button
                                        type='button'
                                        className='btn_del_row'
                                        onClick={() => deleteReference(idx)}
                                    ><img src={trash} alt="X" /></button>
                                )}
                            </li>
                        ))}
                    </ul>
                ))}
            </div>

            {alertMsg.length !== 0 && <AlertMessage alertMsg={alertMsg} setAlert={setAlert} />}
        </div>
    )
}

export default DeleteReferences;