import { useSelector } from "react-redux";

import ModalSelector from "../../selectors/Modal";

import ModalCategories from "../../modal/ModalCategories";
import CreateCategories from "../../modalCreate/CreateCategories";
import UpdateCategories from "../../modalUpdate/UpdateCategories";
import DeleteCategories from "../../modalDelete/DeleteCategories";
import VerifCategories from "../../modalVerif/VerifCategories";

import "./style.css"

function Categories() {
    const process = useSelector(state => state.process);
    
    return (
        <div className='page page_categories'>
            <ModalSelector page='catégorie' un='une' />

            {process.modal === 'list' && <ModalCategories />}
            {process.modal === 'create' && <CreateCategories />}
            {process.modal === 'update' && <UpdateCategories />}
            {process.modal === 'delete' && <DeleteCategories />}
            {process.modal === 'verif' && <VerifCategories />}
        </div>
    )
}

export default Categories;