import "./style.css"

function ConfirmDelete({ toDelete, setToDelete, setConfirm }) {
    return (
        <div className="mdl_msg_of_verif">
            <div className="content">
                <span>Attention</span>
                <p>Vous vous apprétez à supprimer '{toDelete.name_fr}' <br /><br />Êtes vous sûre ?</p>

                <div className="panel">
                    <button onClick={() => setToDelete(null)} className='red'>non</button>
                    <button onClick={() => setConfirm(true)}>oui</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmDelete;