import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../redux";

import "./style.css"

function ModalSelector({ page, un }) {
    const dispatch = useDispatch();
    const process = useSelector(state => state.process);

    return (
        <ul className='modal_selector'>
            <li
                className={process.modal === 'list' ? 'actif' : ''}
                onClick={() => dispatch(setModal('list'))}
            >Mes {page}s</li>

            <li
                className={process.modal === 'create' ? 'actif' : ''}
                onClick={() => dispatch(setModal('create'))}
            >Ajouter {un} {page}</li>

            <li
                className={process.modal === 'update' ? 'actif' : ''}
                onClick={() => dispatch(setModal('update'))}
            >Modifier {un} {page}</li>

            <li
                className={process.modal === 'delete' ? 'actif' : ''}
                onClick={() => dispatch(setModal('delete'))}
            >Supprimer {un} {page}</li>

            {page !== 'référence' &&
                <li
                    className={process.modal === 'verif' ? 'actif' : ''}
                    onClick={() => dispatch(setModal('verif'))}
                >Vérification des {page}s</li>
            }
        </ul>
    )
}

export default ModalSelector;